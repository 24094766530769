.carousel {
    overflow: hidden;
  }
  
  .inner {
    white-space: nowrap;
    transition: transform 0.5s;
  }
  
  .carousel-item {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(255, 255, 255);
    color: var(--white);
  }
  
  .indicators {
    display: flex;
    margin-top: 3px;
    justify-content: center;
  }
  
  .indicators > .updateArrows {
    margin: 5px;
    opacity: 0.8;
    transition: 0.3s;
  }

  .indicators > div >  #activateButtons {
    margin-top: 9px;
    margin-left: 7px;
    margin-right: 7px;
    width: 17px;
    height: 17px;
    border-radius:50%;
    opacity: 0.8;
    transition: 0.3s;
  }

  .indicators > div >  #activateButtons:hover {opacity: 1}
  
  .indicators > div > button.active {
    background-color: rgb(0, 67, 155);
    color: var(--white);
  }
  
  .indicators > .updateArrows:hover  {opacity: 1}
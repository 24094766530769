/* right btn */
.button-action {
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  width: 8.7rem;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  background-color: var(--information);
  color: var(--jasmine);
  inline-size: auto;
  border: none;
  font: 500 16px var(--sisa-font-family);
}

/* active & hover */
.button-action:active,
.button-action:hover {
  background-color: var(--hover);
  cursor: pointer;
}

/* focus */
.button-action:focus {
  background-color: var(--information);
  border: 2px solid #20C5FF;
  transition: box-shadow 0.3s ease;
  outline: none;
}

/* left btn */
.button-action-white {
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  /* width: 8.7rem; */
  padding: 0.5rem 1rem;
  border-radius: 4px;
  background-color: var(--jasmine);
  color: var(--information);
  inline-size: auto;
  border: none;
  font: 600 1rem var(--sisa-font-family);
  border: 1px solid var(--information);
}

/* active & hover */
.button-action-white:active,
.button-action-white:hover {
  background-color: var(--information);
  cursor: pointer;
  color: var(--jasmine);
}

/* focus */
.button-action-white:focus {
  background-color: var(--jasmine);
  border: 2px solid #20C5FF;
  color: var(--information);
  transition: box-shadow 0.3s ease;
  outline: none;
  /* Remove default focus outline */
}

/* disabled */
.button-action-white:disabled,
.button-action:disabled {
  background-color: var(--ghost);
  color: var(--ash);
  font-weight: bold;
  border: none;
}

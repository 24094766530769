.total-savings-container {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.financial-analysis-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 100%;
    justify-content: center;
    align-items: center;
}


@media(max-width: 768px) {
    .total-savings-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        justify-content: center;
        align-items: center;
    }
}
.file-details-table-container {
  width: 100%;
  overflow: auto;
  align-self: flex-start;
  max-height: 50vh;
  font-size: 16px;
  border-right: solid 1px var(--ghost);
  border-top: solid 1px var(--ghost);
}

.file-details-table {
  width: 100%;
}

.header-name {
  width: 50%;
}

.header-size {
  width: 20%;
}

.header-message {
  width: 20%;
}

.header-status {
  width: 10%;
}

.header-action {
  text-align: center;
  width: auto;
  font-weight: 400;
}

.file-details-thead {
  color: #011248;
  position: sticky;
  text-align: left;
  top: 0;
  background-color: var(--ghost);
}

.file-details-thead > tr {
  border-top: solid 1px var(--mist);
}

.file-details-thead > tr > th {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding: 0.5rem 1rem;
  border-left: solid 1px var(--mist);
  border-bottom: solid 1px var(--mist);
  vertical-align: middle;
}

.file-details-thead > tr > th:last-child {
  border-right: solid 1px var(--mist);
}

.file-name-tag {
  display: flex;
  flex-direction: row;
  font-size: 16px;
  line-height: 24px;
  height: fit-content;
  align-items: center;
}

.file-name-tag > p {
  padding: 0 5px;
}

.file-details-tbody > tr {
  background-color: var(--white);
}

.file-details-tbody > tr > td {
  color: var(--charcoal);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding: 0.5rem 1rem;
  border-left: solid 1px var(--ghost);
  border-bottom: solid 1px var(--ghost);
}

.delete-row {
  text-align: center;
}

.delete-row-button {
  cursor: pointer;
  color: var(--error);
}

.delete-row-button:hover {
  color: hsl(0, 100%, 70%);
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loading-spinner {
  width: 75px;
  height: 75px;
  border: 15px solid #c7c7c7;
  /* Light grey */
  border-top: 15px solid #346aff;
  /* Blue */
  border-radius: 50%;
  animation: spinner 2s linear infinite;
}

.spinner-container {
  margin: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10vh;
}

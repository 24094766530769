.card-container {
    width: calc(100% / 6);
    display: flex;
    flex-direction: column;
    border: solid 1px rgb(205, 205, 205);
}

.card-top,
.card-bot {
    padding: .5em;
}

.card-title {
    text-align: center;
    font-size: 1vw;
    font-weight: 600;
}

.card-units {
    text-align: center;
    font-size: 0.6vw;
}

.card-value {
    text-align: center;
    font-size: 1vw;
    font-weight: 600;
}


@media(max-width: 1024px) {
    .card-container {
        width: calc(100%);
    }
    .card-title {
        text-align: center;
        font-size: 14px;
        font-weight: 600;
    }
    .card-units {
        text-align: center;
        font-size: 12px;
    }
    .card-value {
        text-align: center;
        font-size: 14px;
        font-weight: 600;
    }
}
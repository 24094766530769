body {
  font-family: var(--sisa-font-family);
}

#portfolio-plus {
  min-width: 100%;
}

#portfolio-plus td,
#portfolio-plus th {
  border-left: 0.25px solid #ddd;
  border-right: 0.25px solid #ddd;
  padding: 0.5rem;
}

#portfolio-plus tr:nth-child(1) {
  border-top: 0.125px solid #ddd;
}

#portfolio-plus tr:nth-child(2) {
  border-top: 0.125px solid #ddd;
}

#portfolio-plus tr:nth-child(4n) {
  background-color: #f2f2f2;
  border-top: 0.125px solid #ddd;
}

#portfolio-plus tr:nth-child(4n + 2) {
  border-top: 0.125px solid #ddd;
}

#portfolio-plus tbody:nth-last-child(1) {
  border-bottom: 0.125px solid #ddd;
}

#portfolio-plus tr:hover {
  background-color: hsl(203, 92%, 90%);
}

#portfolio-plus th:hover {
  background-color: navy;
  cursor: default;
}

#portfolio-plus th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #2359a1;
  color: var(--white);
  text-transform: capitalize;
  font-weight: lighter;
}

#portfolio-plus>tr:hover {
  background: #fcf3d0;
}

.total-row {
  background: lightcyan;
  font-weight: bold;
}

#portfolio-plus span:hover {
  cursor: pointer;
}

.table-title {
  cursor: auto;
  background-color: transparent;
  color: var(--indigo);
  font: 600 20px/28px var(--sisa-font-family);
}

/* dashboard table style */
.table-title-text {
  padding: 1rem;
  color: var(--indigo);
  font: 600 20px/28px var(--sisa-font-family);
}

/* dashboard building name */
.building-name {
  font: 600 16px/20px var(--sisa-font-family);
  color: var(--information);
}

.building-name:hover {
  cursor: pointer;
  text-decoration: underline;
  color: var(--information);
}

.table-cell-text {
  padding-right: 0.5em;
  white-space: nowrap;
}

.btn-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  object-fit: contain;
  margin: 0.5em;
  gap: 0.5rem;
}

.btn-container:hover {
  cursor: pointer;
}

.create-bundle-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  width: 8.7rem;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  background-color: var(--jasmine);
  width: 60%;
  color: var(--information);
  border: 1px solid var(--information);
  font: 600 16px var(--sisa-font-family);
}

.create-bundle-btn:hover,
.create-bundle-btn:active {
  background-color: var(--information);
  color: var(--jasmine);
  cursor: pointer;
}

.create-bundle-btn:focus {
  background-color: var(--jasmine);
  color: var(--information);
  border: 2px solid #20C5FF;
  transition: box-shadow 0.3s ease;
  outline: none;
  /* Remove default focus outline */
  /* box-shadow: 0 0 0 1px #20C5FF; */
}

.create-bundle-btn:disabled {
  background-color: var(--ghost);
  color: var(--ash);
}

#expanded-ecm {
  width: 100%;
}

#expanded-ecm td,
#expanded-ecm th {
  border-left: 0.25px solid #ddd;
  border-right: 0.25px solid #ddd;
  border-bottom: 0.25px solid #ddd;
  padding: 0.5rem;
}

#expanded-ecm tr:nth-child(even) {
  background-color: #f2f2f2;
}

#expanded-ecm tr:hover {
  background-color: hsl(203, 92%, 90%);
}

#expanded-ecm th:hover {
  background-color: rgb(180, 180, 180);
  cursor: pointer;
}

#expanded-ecm th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #838383;
  color: var(--white);
  text-transform: capitalize;
  font-weight: lighter;
}

#expanded-ecm>tr:hover {
  background: #fcf3d0;
}

#expanded-ecm span:hover {
  cursor: pointer;
}

#table-decal-signs {
  transition-duration: 0.4s;
}

#table-decal-signs:hover {
  cursor: pointer;
  opacity: 0.5;
}

#expanded-ecm tr:nth-child(even) {
  background-color: #f2f2f2;
}

#expanded-ecm tr:nth-child(even):hover {
  background-color: #f2f2f2;
  background: #fcf3d0;
}

#expanded-column {
  padding: 0;
}

#portfolio {
  border-collapse: separate;
  border-spacing: 0;
}

#portfolio thead>tr>th:last-child {
  border-right: 1px solid var(--mist);
}

#portfolio tbody>tr>td:last-child {
  border-right: 1px solid var(--ghost);
}

#portfolio tbody>tr:last-child>td {
  border-bottom: none;
}

#portfolio td {
  border-left: 1px solid var(--ghost);
  border-bottom: 1px solid var(--ghost);
  color: var(--charcoal);
  padding: .5rem 1rem;
}

#portfolio tr {
  background-color: var(--white);
}

#portfolio tr:hover {
  background-color: var(--ghost);
}

#portfolio th:hover {
  background-color: var(--mist);
  cursor: pointer;
}

#portfolio th {
  border-top: 1px solid var(--mist);
  border-left: 1px solid var(--mist);
  border-bottom: 1px solid var(--mist);
  padding: .5rem 1rem;
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: var(--ghost);
  color: var(--indigo);
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.total-row {
  background: lightcyan;
  font-weight: bold;
}

/* #portfolio span:hover {
  cursor: pointer;
} */

.columnItem .table-headers,
.table-headers {
  display: none;
}

#accordion-swtich:hover {
  cursor: pointer;
}

.expanded-element {
  display: none;
}

#accordion-swtich {
  display: none;
}

#expanded-table-mobile {
  display: none;
}

/* Apply sticky behavior to the description header */
th.sticky-header {
  position: sticky;
  top: 0;
  left: 0;
  background-color: #2359a1;
  z-index: 3;
  border-right: solid 1px var(--mist);
}

/* Apply sticky behavior to the first column on the upgrade table */
.row-upgrade td:first-child {
  position: sticky;
  top: 0;
  left: 0;
  background-color: var(--white);
  z-index: 2;
  border-right: solid 1px var(--ghost);
}

.row-upgrade td:first-child:hover {
  background-color: var(--ghost);
}

.decarb-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: var(--charcoal);
  width: 100%;
  cursor: pointer;
  gap: 1rem;
}

.decarb-container span {
  /* margin-left: 8px; */
  text-align: center;
  font: 500 16px/24px var(--sisa-font-family);
}

.decarb-font-awesome {
  margin-left: 6px;
  width: 12px;
  height: 24px;
}

.decarb-container:hover,
.decarb-container:hover span {
  color: var(--information);
}

.decarb-font-awesome:hover {
  fill: var(--information);
}

.header-border {
  border: 1px solid #ddd;
}

.upgrade-table {
  width: 100%;
  overflow-x: auto;
  font-family: var(--sisa-font-family);
}

.dash-name-align {
  display: flex;
  align-items: center;
}

.dash-name-align p {
  width: 100%;
  margin-top: 0.5rem;
  inline-size: max-content;
}

.table-header-row {
  font-family: var(--sisa-font-family);
}

@media (max-width: 430px) {
  .create-bundle-btn {
    font-size: 0.8rem;
  }
}

@media (max-width: 768px) {
  table {
    width: 100%;
  }

  .dash-table-container {
    width: 100%;
    padding: 0 1rem 0 1rem;
  }

  .table-title {
    width: 100%;
  }

  #thead-row,
  #thread-expanded-row,
  #thread-row-status {
    display: none;
  }

  td {
    border-bottom: 1px solid rgb(0, 0, 0);
    padding: 0px !important;
  }

  .total-row,
  .columnItem {
    display: block !important;
  }

  .columnItem .table-headers {
    width: 60%;
    display: inline-block;
    font-weight: bolder;
    font-family: var(--sisa-font-family);
  }

  #building-num,
  #table-decal-signs {
    display: inline-block;
  }

  .td-element {
    display: none !important;
  }

  #accordion-swtich {
    display: inline-block;
  }

  tbody>td {
    display: block;
  }

  .expanded-table,
  .portfolio-table-data {
    display: grid !important;
    grid-template-columns: 1fr 1fr;
  }

  #expanded-table-pc {
    display: none;
  }

  #expanded-table-mobile {
    display: block;
    border-bottom: none;
  }

  #td-first-element {
    border-top: 1px solid #ddd;
  }

  .hide-on-mobile {
    display: none;
  }

  .create-bundle-btn {
    line-height: 1;
  }
}

.navigationTabs {
  display: flex;
  width: 100%;
  height: 100%;
}
.navigationTab {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.25rem;
  padding: 0.25rem 0.75rem;
  border-radius: 0.25rem 0.25rem 0rem 0rem;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  border-left: 2px solid #fff;
  background: #e6e6e6;
  color: #224bff;
  cursor: pointer;
  text-wrap: nowrap;
}
.navigationTab.selected {
  background: #f9f9f9;
  color: #011148;
}
.navigationTab:hover {
  color: #2e45be;
}
.navigationTab:focus {
  border-top: 3px solid #20c5ff;
  border-right: 3px solid #20c5ff;
  border-left: 3px solid #20c5ff;
  color: #224bff;
  transition: box-shadow 0.3s ease;
  outline: none;
}
.navigationTab.disabled {
  color: #737373;
  background: #f9f9f9;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  border-left: 2px solid #fff;
  cursor: not-allowed;
}
.navigationTab__large {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.25rem;
  padding: 0.25rem 0.75rem;
  border-radius: 0.25rem 0.25rem 0rem 0rem;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  border-left: 2px solid #fff;
  background: #e6e6e6;
  color: #224bff;
  cursor: pointer;
  text-wrap: nowrap;
  padding: 0.75rem 1rem 1rem 1rem;
  font: 600 0.875rem/1.25rem Work sans;
}
.navigationTab__large.selected {
  background: #f9f9f9;
  color: #011148;
}
.navigationTab__large:hover {
  color: #2e45be;
}
.navigationTab__large:focus {
  border-top: 3px solid #20c5ff;
  border-right: 3px solid #20c5ff;
  border-left: 3px solid #20c5ff;
  color: #224bff;
  transition: box-shadow 0.3s ease;
  outline: none;
}
.navigationTab__large.disabled {
  color: #737373;
  background: #f9f9f9;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  border-left: 2px solid #fff;
  cursor: not-allowed;
}
.navigationTab__small {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.25rem;
  padding: 0.25rem 0.75rem;
  border-radius: 0.25rem 0.25rem 0rem 0rem;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  border-left: 2px solid #fff;
  background: #e6e6e6;
  color: #224bff;
  cursor: pointer;
  text-wrap: nowrap;
  padding: 0.625rem 0.75rem 0.75rem 0.75rem;
  font: 600 0.75rem/1.25rem Work sans;
}
.navigationTab__small.selected {
  background: #f9f9f9;
  color: #011148;
}
.navigationTab__small:hover {
  color: #2e45be;
}
.navigationTab__small:focus {
  border-top: 3px solid #20c5ff;
  border-right: 3px solid #20c5ff;
  border-left: 3px solid #20c5ff;
  color: #224bff;
  transition: box-shadow 0.3s ease;
  outline: none;
}
.navigationTab__small.disabled {
  color: #737373;
  background: #f9f9f9;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  border-left: 2px solid #fff;
  cursor: not-allowed;
}
.navigationTab__left-icon {
  display: flex;
  height: 1.625rem;
  padding: 0.25rem 0.3125rem 0.25rem 0;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1.125rem;
  font-weight: 300;
  line-height: normal;
}
.navigationTab__left-icon.selected {
  color: #011148;
}
.navigationTab__right-icon {
  display: flex;
  height: 1.625rem;
  padding: 0.25rem 0 0.25rem 0.3125rem;
  justify-content: center;
  align-items: center;
  color: #008611;
  text-align: center;
  font-size: 1.125rem;
  font-weight: 300;
  line-height: normal;
}
.navigationTab__right-icon--error {
  color: #cf0a2e;
}
@media (min-width: 320px) and (max-width: 479px) {
  .navigationTabs {
    display: none;
  }
  .navigationTabs-mobile {
    display: flex;
    overflow-x: hidden;
    white-space: nowrap;
    width: 100%;
    z-index: 1;
  }
  .navigationTab__small {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.25rem;
    padding: 0.25rem 0.75rem;
    border-radius: 0.25rem 0.25rem 0rem 0rem;
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
    border-left: 2px solid #fff;
    background: #e6e6e6;
    color: #224bff;
    cursor: pointer;
    text-wrap: nowrap;
    width: 100%;
    padding: 0.625rem 0.75rem 0.75rem 0.75rem;
    font: 600 0.75rem/1.25rem Work sans;
  }
  .navigationTab__small.selected {
    background: #f9f9f9;
    color: #011148;
  }
  .navigationTab__small:hover {
    color: #2e45be;
  }
  .navigationTab__small:focus {
    border-top: 3px solid #20c5ff;
    border-right: 3px solid #20c5ff;
    border-left: 3px solid #20c5ff;
    color: #224bff;
    transition: box-shadow 0.3s ease;
    outline: none;
  }
  .navigationTab__small.disabled {
    color: #737373;
    background: #f9f9f9;
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
    border-left: 2px solid #fff;
    cursor: not-allowed;
  }
  .navigationTab-mobile__large {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 0.25rem;
    border-radius: 0.25rem 0.25rem 0rem 0rem;
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
    border-left: 2px solid #fff;
    background: #e6e6e6;
    color: #224bff;
    cursor: pointer;
    text-wrap: nowrap;
    padding: 0.25rem 0.375rem 0.375rem 0.75rem;
    font: 600 0.75rem/1.25rem Work sans;
  }
  .navigationTab-mobile__large.selected {
    background: #f9f9f9;
    color: #011148;
  }
  .navigationTab-mobile__large:hover {
    color: #2e45be;
  }
  .navigationTab-mobile__large:focus {
    border-top: 3px solid #20c5ff;
    border-right: 3px solid #20c5ff;
    border-left: 3px solid #20c5ff;
    color: #224bff;
    transition: box-shadow 0.3s ease;
    outline: none;
  }
  .navigationTab-mobile__large.disabled {
    color: #737373;
    background: #f9f9f9;
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
    border-left: 2px solid #fff;
    cursor: not-allowed;
  }
  .navigationTab-mobile__large .navigationTab__dot-icon {
    display: sticky;
    z-index: 2;
    width: 1.75rem;
    height: 1.625rem;
    padding: 0.25rem 0.375rem;
    justify-content: center;
    align-items: center;
  }
  .navigationTab__dropdown {
    overflow: hidden;
    display: flex;
    width: 11.25rem;
    padding: 0.5rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.125rem;
    position: absolute;
    top: 18rem;
    right: 3rem;
    border-radius: 0.25rem;
    background: #fff;
    box-shadow: 2px 2px 2px 0px rgba(20, 20, 20, 0.15);
    z-index: 1;
  }
  .navigationTab__dropdown .navigation-mobile__small {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.25rem;
    padding: 0.25rem 0.75rem;
    border-radius: 0.25rem 0.25rem 0rem 0rem;
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
    border-left: 2px solid #fff;
    background: #e6e6e6;
    color: #224bff;
    cursor: pointer;
    text-wrap: nowrap;
    padding: 0.625rem 0.75rem 0.75rem 0.75rem;
    font: 600 0.75rem/1.25rem Work sans;
  }
  .navigationTab__dropdown .navigation-mobile__small.selected {
    background: #f9f9f9;
    color: #011148;
  }
  .navigationTab__dropdown .navigation-mobile__small:hover {
    color: #2e45be;
  }
  .navigationTab__dropdown .navigation-mobile__small:focus {
    border-top: 3px solid #20c5ff;
    border-right: 3px solid #20c5ff;
    border-left: 3px solid #20c5ff;
    color: #224bff;
    transition: box-shadow 0.3s ease;
    outline: none;
  }
  .navigationTab__dropdown .navigation-mobile__small.disabled {
    color: #737373;
    background: #f9f9f9;
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
    border-left: 2px solid #fff;
    cursor: not-allowed;
  }
  .navigationTab-mobile__label {
    font: 500 0.75rem/1.25rem Work sans;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .navigationTabs {
    display: none;
  }
  .navigationTabs-mobile {
    display: flex;
    overflow-x: hidden;
    white-space: nowrap;
    width: 100%;
    z-index: 1;
  }
  .navigationTab__large {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.25rem;
    padding: 0.25rem 0.75rem;
    border-radius: 0.25rem 0.25rem 0rem 0rem;
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
    border-left: 2px solid #fff;
    background: #e6e6e6;
    color: #224bff;
    cursor: pointer;
    text-wrap: nowrap;
    width: 100%;
    font: 600 0.875rem/1.25rem Work sans;
    height: 2.75rem;
  }
  .navigationTab__large.selected {
    background: #f9f9f9;
    color: #011148;
  }
  .navigationTab__large:hover {
    color: #2e45be;
  }
  .navigationTab__large:focus {
    border-top: 3px solid #20c5ff;
    border-right: 3px solid #20c5ff;
    border-left: 3px solid #20c5ff;
    color: #224bff;
    transition: box-shadow 0.3s ease;
    outline: none;
  }
  .navigationTab__large.disabled {
    color: #737373;
    background: #f9f9f9;
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
    border-left: 2px solid #fff;
    cursor: not-allowed;
  }
  .navigationTab-mobile__large {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 0.25rem;
    height: 2.75rem;
    border-radius: 0.25rem 0.25rem 0rem 0rem;
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
    border-left: 2px solid #fff;
    background: #e6e6e6;
    color: #224bff;
    cursor: pointer;
    text-wrap: nowrap;
    padding: 0.75rem 1rem 1rem 1rem;
    font: 600 0.875rem/1.25rem Work sans;
  }
  .navigationTab-mobile__large.selected {
    background: #f9f9f9;
    color: #011148;
  }
  .navigationTab-mobile__large:hover {
    color: #2e45be;
  }
  .navigationTab-mobile__large:focus {
    border-top: 3px solid #20c5ff;
    border-right: 3px solid #20c5ff;
    border-left: 3px solid #20c5ff;
    color: #224bff;
    transition: box-shadow 0.3s ease;
    outline: none;
  }
  .navigationTab-mobile__large.disabled {
    color: #737373;
    background: #f9f9f9;
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
    border-left: 2px solid #fff;
    cursor: not-allowed;
  }
  .navigationTab-mobile__large .navigationTab__dot-icon {
    display: sticky;
    z-index: 2;
    width: 1.75rem;
    height: 1.625rem;
    padding: 0.25rem 0.375rem;
    justify-content: center;
    align-items: center;
  }
  .navigationTab__dropdown {
    overflow: hidden;
    display: flex;
    width: 11.25rem;
    padding: 0.5rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 0.125rem;
    position: absolute;
    top: 18rem;
    right: 3rem;
    border-radius: 0.25rem;
    background: #fff;
    box-shadow: 2px 2px 2px 0px rgba(20, 20, 20, 0.15);
    z-index: 1;
  }
  .navigationTab__dropdown .navigation-mobile__small {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.25rem;
    padding: 0.25rem 0.75rem;
    border-radius: 0.25rem 0.25rem 0rem 0rem;
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
    border-left: 2px solid #fff;
    background: #e6e6e6;
    color: #224bff;
    cursor: pointer;
    text-wrap: nowrap;
    font: 600 0.75rem/1.25rem Work sans;
  }
  .navigationTab__dropdown .navigation-mobile__small.selected {
    background: #f9f9f9;
    color: #011148;
  }
  .navigationTab__dropdown .navigation-mobile__small:hover {
    color: #2e45be;
  }
  .navigationTab__dropdown .navigation-mobile__small:focus {
    border-top: 3px solid #20c5ff;
    border-right: 3px solid #20c5ff;
    border-left: 3px solid #20c5ff;
    color: #224bff;
    transition: box-shadow 0.3s ease;
    outline: none;
  }
  .navigationTab__dropdown .navigation-mobile__small.disabled {
    color: #737373;
    background: #f9f9f9;
    border-top: 2px solid #fff;
    border-right: 2px solid #fff;
    border-left: 2px solid #fff;
    cursor: not-allowed;
  }
  .navigationTab-mobile__label {
    font: 500 0.75rem/1.25rem Work sans;
  }
}
@media (min-width: 768px) and (max-width: 1519px) {
  .navigationTabs-mobile {
    display: none;
  }
  .navigationTabs {
    display: flex;
    overflow-x: hidden;
    white-space: nowrap;
    width: 100%;
    position: relative;
  }
  .navigationTab__small {
    width: 100%;
    position: relative;
    white-space: nowrap;
  }
  .navigationTab__button {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #224bff;
    text-align: center;
    font-size: 1.125rem;
    font-weight: 300;
    line-height: normal;
    border-top: 2px solid #fff;
    background: #e6e6e6;
    width: 2.25rem;
    padding: 0.3125rem 0.375rem;
    cursor: pointer;
  }
  .navigationTab__left-button {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #224bff;
    text-align: center;
    font-size: 1.125rem;
    font-weight: 300;
    line-height: normal;
    border-top: 2px solid #fff;
    background: #e6e6e6;
    width: 2.25rem;
    padding: 0.3125rem 0.375rem;
    cursor: pointer;
    display: none;
    position: sticky;
    top: 0;
    bottom: 0;
    left: 0;
    width: 2.75rem;
    border-right: 3px solid #fff;
    z-index: 1;
  }
  .navigationTab__right-button {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #224bff;
    text-align: center;
    font-size: 1.125rem;
    font-weight: 300;
    line-height: normal;
    border-top: 2px solid #fff;
    background: #e6e6e6;
    width: 2.25rem;
    padding: 0.3125rem 0.375rem;
    cursor: pointer;
    position: sticky;
    right: -2px;
    top: 0;
    bottom: 0;
    width: 2.75rem;
    border-left: 3px solid #fff;
  }
  .navigationTab__arrow-icon {
    display: flex;
    width: 1.75rem;
    height: 1.625rem;
    justify-content: center;
    align-items: center;
  }
  .navigationTab__label {
    font: 600 0.75rem/1.25rem Work sans;
  }
}
@media (min-width: 1520px) {
  .navigationTabs-mobile {
    display: none;
  }
  .navigationTab__large {
    width: 100%;
  }
  .navigationTab__left-button {
    display: none;
  }
  .navigationTab__arrow-icon {
    display: none;
  }
  .navigationTab__right-button {
    display: none;
  }
  .navigationTab__label {
    font: 600 0.75rem/1.25rem Work sans;
  }
}

.textarea {
  display: grid;
  position: relative;
  width: 100%;
  height: 100%;
  gap: 8px;
}
.textarea__label {
  font-size: 16px;
  font-weight: 500;
  color: #141414;
}
.textarea__lable--default {
  color: #141414;
}
.textarea__label--disabled {
  color: #737373;
}
.textarea__label--required {
  color: #cf0a2e;
}
.textarea__container {
  display: flex;
  position: relative;
  justify-content: flex-start;
  width: 100%;
}
.textarea__icon {
  position: absolute;
  z-index: 1;
  margin: 8px;
}
.textarea__icon--left {
  left: 0;
}
.textarea__icon--right {
  right: 0;
}
.textarea__field {
  position: relative;
  border: 1px solid #737373;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 400;
  padding: 10px 16px;
  width: 100%;
  height: 100%;
  background-color: #fff;
}
.textarea__field::placeholder {
  color: #737373;
}
.textarea__field--icon--left {
  padding-left: 30px;
}
.textarea__field--icon--right {
  padding-right: 30px;
}
.textarea__field--default {
  color: #404040;
}
.textarea__field--default:focus {
  outline: none;
  box-shadow: 0px 0px 0px 2px #20c5ff;
}
.textarea__field--disabled {
  border: 1px solid #b3b3b3;
  background-color: rgba(230, 230, 230, 0.5);
  color: #b3b3b3;
}
.textarea__field--disabled:focus {
  outline: none;
}
.textarea__field--readonly {
  background-color: #e6e6e6;
  color: #404040;
}
.textarea__field--readonly:focus {
  outline: none;
}
.textarea__field--invalid {
  border: 1px solid #cf0a2e;
}
.textarea__field--invalid:focus {
  outline: none;
  box-shadow: 0px 0px 0px 2px rgba(207, 10, 46, 0.5);
}
.textarea__field--valid {
  border: 1px solid #008611;
}
.textarea__field--valid:focus {
  outline: none;
  box-shadow: 0px 0px 0px 2px rgba(0, 134, 17, 0.5);
}
.textarea__valication {
  position: absolute;
  top: 75px;
  left: 5px;
  font-size: 12px;
}
.textarea__validation--invalid {
  color: #cf0a2e;
}
.textarea__validation--valid {
  color: #008611;
}

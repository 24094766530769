.savings-table {
    border: solid 1px rgb(205, 205, 205);
    border-collapse: collapse;
    text-align: center;
}

.savings-table-row {
    justify-content: center;
    align-items: center;
}

.savings-table-rows td,
.savings-table-rows th {
    color: var(--indigo);
    text-transform: none;
    border-right: solid 1px rgb(205, 205, 205);
    border-bottom: solid 1px rgb(205, 205, 205);
}

.savings-table-cell {
    font-size: 1vw;
    width: calc(100% / 8);
    padding: 0.5em;
}

.savings-header {
    font-size: 1vw;
    font-weight: 600;
    text-align: center;
}

.savings-units {
    font-size: 0.6vw;
    text-align: center;
}

.file-uploader {
  padding: 24px 32px;
  border-radius: 4px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
  width: 80%;
}
.file-uploader::backdrop {
  background-color: rgba(0, 0, 0, 0.5);
}
.dialog-header__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}
.page__subheader h2 {
  align-items: center;
  display: flex;
  column-gap: 10px;
  font: 600 1.125rem/2rem var(--sisa-font-family);
  line-height: 20px;
}
.storage-indicator__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
}
.file__stats {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
}
.file-uplpoad__buttons {
  display: flex;
  font-size: 16px;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-inline: auto;
}
.add-buttons__container {
  display: flex;
  padding: 0.5rem;
  gap: 0.5rem;
}
.action-buttons__container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem;
}
.stats__container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.25rem;
}
.file-input-stats {
  display: flex;
  justify-content: flex-end;
}
.file-input-stats p {
  font-size: 16px;
  padding: 0.25rem 0.5rem;
}
.dialog__header {
  color: #011148;
  font-size: 28px;
  font-weight: 600;
  line-height: 36px;
}
.dialog__close-icon {
  color: #141414;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  margin: 5px 7px;
  cursor: pointer;
}
.dialog__text {
  display: flex;
  flex-direction: column;
  gap: 16px;
  color: #404040;
  padding: 8px;
  margin: 16px 0px;
}
.dialog__text p:first-child {
  color: #404040;
  font-size: 16px;
  font-weight: 600;
  line-height: 32px;
}
.dialog__text p:nth-child(2) {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  font-style: italic;
}
.dropzone__container {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: dashed 2px lightskyblue;
  background-color: hsl(203, 92%, 95%);
  min-height: 10vh;
  overflow-y: auto;
  padding: 18px;
  margin: 0 0.5rem 8px 0.5rem;
}
.dropzone__icon--download {
  color: #011148;
  text-align: center;
  font-size: 36px;
  font-weight: 300;
}
.dropzone__content {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}
.dropzone__content p {
  color: #011148;
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
}
.dropzone__content span {
  color: #224bff;
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  text-decoration: underline;
  cursor: pointer;
  position: relative;
}
.dropzone__icon--clip {
  color: #011148;
  text-align: center;
  font-size: 18px;
  font-weight: 300;
}
.dropzone-upload__buttons {
  font-size: 12px;
  font-weight: 500;
  display: flex;
  position: absolute;
  top: 55%;
  left: 37%;
  padding: 8px;
  flex-direction: column;
  gap: 2px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 2px 2px 2px 0px rgba(20, 20, 20, 0.15);
  color: #224bff;
}
.dropzone-upload__buttons div {
  height: 40px;
  padding: 12px;
  background-color: rgba(249, 249, 249, 0.25);
}
.dropzone-upload__buttons div:hover,
.dropzone-upload__buttons div:focus,
.dropzone-upload__buttons div:active {
  background-color: #e6e6e6;
  color: #2e45be;
  cursor: pointer;
}
.file-uploader__details {
  display: grid;
  grid-template-columns: 1fr 5fr 3fr 2fr 1fr;
  padding: 8px 16px;
  align-items: center;
  gap: 16px;
  align-self: stretch;
  border-radius: 4px;
  border: 1px solid var(--Mist, #b3b3b3);
  color: #141414;
}
.file-details-table__container {
  width: 100%;
  overflow: auto;
  align-self: flex-start;
  max-height: 50vh;
  font-size: 16px;
  border-right: solid 1px var(--ghost);
  border-top: solid 1px var(--ghost);
}
.file-details__table {
  width: 100%;
}
.file-details__table--disabled {
  color: #737373;
  pointer-events: none;
  opacity: 0.5;
}
.file-details__thead {
  color: #011248;
  position: sticky;
  text-align: left;
  top: 0;
  background-color: var(--ghost);
}
.file-details__thead > tr {
  border-top: solid 1px var(--mist);
}
.file-details__thead > tr > th {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding: 0.5rem 1rem;
  border-left: solid 1px var(--mist);
  border-bottom: solid 1px var(--mist);
  vertical-align: middle;
}
.file-details__thead > tr > th:last-child {
  border-right: solid 1px var(--mist);
}
.file-name__container {
  display: grid;
  grid-template-columns: auto 10%;
  font-size: 16px;
  align-items: center;
}
.file-name-tag > p {
  padding: 0 5px;
}
.file-name {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px;
}
.file-name__input {
  border-radius: 4px;
  border: 1px solid var(--Ash, #737373);
  background: var(--White, #fff);
  padding: 3px;
  width: 100%;
}
.name-edit__button {
  color: var(--information);
}
.name-edit__button--disabled {
  color: var(--mist);
  cursor: not-allowed;
}
.file-details__tbody > tr {
  background-color: var(--white);
}
.file-details__tbody > tr > td {
  color: var(--charcoal);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding: 0.5rem 1rem;
  border-left: solid 1px var(--ghost);
  border-bottom: solid 1px var(--ghost);
}
.download-row__button {
  display: flex;
  cursor: pointer;
  color: var(--information);
  justify-content: center;
  align-self: center;
}
.delete-row {
  text-align: center;
}
.delete-row__button {
  display: flex;
  cursor: pointer;
  color: var(--error);
  justify-content: center;
  align-self: center;
}
.delete-row__button:hover {
  color: hsl(0, 100%, 70%);
}
.empty-table-note {
  text-align: center;
}

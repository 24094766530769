.admin-dashboardPage .topBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 70%;
  border: 1px solid #ddd;
}

.admin-dashboardPage .topBox .box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  border-bottom: 1px solid #ddd;
  width: 100%;
  height: 100%;
}

.admin-dashboardPage .topBox .topBar {
  background-color: #112b4e;
  height: 3.5rem;
}

.admin-dashboardPage .topBox .boxNumber {
  border-bottom: none;
}

.admin-dashboardPage .topBox .box .text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
  padding: 1rem;
  text-align: center;
  color: var(--white);
}

.admin-dashboardPage .text {
  font-size: 14px;
}

.admin-dashboardPage .border {
  border-right: 1px solid #ddd;
}

.admin-dashboardPage .line {
  border-right: 1px solid #ddd;
}

.admin-dashboardPage .topBox .box .account {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
  padding: 1rem;
  text-align: center;
}

.admin-dashboardPage .topBox .box .account .text2 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 50%;
  height: 100;
  font-size: 12px;
  color: var(--white);
}

.admin-dashboardPage .topBox .boxNumber .number {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
  padding: 1rem;
}

.admin-dashboardPage .bigContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.admin-dashboardPage .bigContainer .container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
  border-radius: 5px;
  margin: 1rem;
  padding: 1rem;
}

.admin-dashboardPage .bigContainer .container .boxes {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2%;
  width: 100%;
  height: 100%;
}

.admin-dashboardPage .bigContainer .container .boxes .box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 48%;
  height: 100%;
}

.admin-dashboardPage .boxes .box .pieChart {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 31rem;
}

.admin-dashboardPage .bigContainer .container .boxes .tableChart {
  height: 31rem;
  
}

.admin-dashboardPage .bigContainer .container .titles {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0 1rem 1rem 0;
  gap: 5rem;
}

.admin-dashboardPage .bigContainer .bottomContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 95%;
  height: 100%;
  margin: 1rem;
  padding: 1rem;
}

.admin-dashboardPage .bigContainer .bottomContainer > .widget-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #112b4e;
  /* padding: 1.5rem 1.5rem 1.5rem 0.5rem; */
  width: 100%;
  height: 3.5rem;
  border: 2px solid #ddd;
}

.admin-dashboardPage .bigContainer .widget-header > h2 {
  font-size: 14px;
  color: var(--white);
  font-family: var(--sisa-font-family);
}

.admin-dashboardPage .bigContainer .bottomContainer .graph {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border: 1px solid var(--ghost);
  border-top: none;
}

.dash-account-table {
  margin-top: 4rem;
}
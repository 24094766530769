.dropdown__box {
  position: relative;
}
.dropdown--toggle {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  flex-shrink: 0;
  color: #224bff;
  cursor: pointer;
}
.dropdown__icon {
  display: flex;
  width: 1.125rem;
  height: 1.04463rem;
  padding: 0.0625rem 0.125rem 0.10713rem 0.125rem;
  justify-content: center;
  align-items: center;
}
.dropdown__text {
  font-family: "Work Sans", sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem;
}
.dropdown__menu {
  display: flex;
  padding: 0.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.125rem;
  border-radius: 0.25rem;
  background: #fff;
  box-shadow: 2px 2px 2px 2px rgba(20, 20, 20, 0.15);
  width: 9.875rem;
  height: auto;
  position: absolute;
  top: calc(100% + 0.2rem);
  z-index: 1;
}

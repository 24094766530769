/* btns in general without icons */
.download {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  min-width: 8.7rem;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  background-color: var(--information);
}

.download-text {
  color: var(--jasmine);
  font: 600 1rem var(--sisa-font-family);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  border-radius: 4px;
  height: 2.5rem;
  min-width: 8.7rem;
  padding: 0.5rem 1rem;
}

/* btn with icons like downloadPDF or CSV */
.dash-download {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  min-width: 10.44rem;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  background-color: var(--information);
}

.dash-download-text {
  color: var(--jasmine);
  font: 600 1rem var(--sisa-font-family);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 4px;
  height: 2.5rem;
  min-width: 10.44rem;
  padding: 0.5rem 1rem;
}

.dash-download-text p {
  width: 100%;
  font-size: 16px;
  font-family: var(--sisa-font-family);
}

.download:active,
.download:hover,
.dash-download:active,
.dash-download:hover {
  background-color: var(--hover);
  cursor: pointer;
}

.download:focus,
.download-text:focus,
.dash-download-text:focus,
.dash-download:focus {
  background-color: var(--information);
  border: 2px solid #20c5ff;
  transition: box-shadow 0.3s ease;
  outline: none;
  /* Remove default focus outline */
  box-shadow: 0 0 0 1px #20C5FF;
}

@media (max-width: 430px) {
  .download {
    font-size: 0.8rem;
    width: 6rem;
  }

}

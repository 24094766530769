.expandable__container {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.header__container {
  display: flex;
  align-items: center;
  justify-content: start;
  cursor: pointer;
  gap: 12px;
}
.header--title {
  font-size: 18px;
  font-weight: 600;
}
.header__icon {
  font-size: 20px;
}
.expandable__body {
  padding: 12px;
}

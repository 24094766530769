input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.basic-info-row-two-col {
  display: grid;
  justify-items: center;
  grid-template-columns: 1fr 1fr;
}

.basic-info-row-three-col {
  display: grid;
  justify-items: center;
  grid-template-columns: 1fr 1fr 1fr;
}

.basic-info-row-four-col {
  display: grid;
  justify-items: center;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.basic-info-row-one-two-col {
  display: grid;
  justify-items: center;
  grid-template-columns: 1fr 2fr;
}

.basic-info-row-two-one-one-col {
  display: grid;
  justify-items: center;
  grid-template-columns: 2fr 1fr 1fr;
}

.basic-info-row-hr {
  display: grid;
  justify-items: center;
  grid-template-columns: 3fr 0.94fr;
  gap: 24px;
}

.label-input-container {
  display: inline-block;
  margin: 5px 10px;
  padding: 0.5rem;
  width: 100%;
}

.property-info-input {
  border: 1px solid #dbdbdb;
  font-size: 14px;
  padding: 10px;
  width: 100%;
  height: 2.5rem;
  margin: 0.5rem 0;
}

.action-btn-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 1rem;
  width: 100%;
  height: 60px;
}

/* ECM Table */

.ecm-table-body-scroll {
  overflow-y: scroll;
  min-height: 10vh;
  max-height: 60vh;
  /* margin: .5em 0; */
  border-bottom: solid 1px var(--ghost);
}

.ecm-table-container {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.bundle-table-container th,
.ecm-table-container th {
  color: #011248;
  top: 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  background-color: var(--ghost);
  text-transform: none;
  padding: 0.5rem;
  border-top: solid 1px var(--mist);
  border-bottom: solid 1px var(--mist);
  border-right: solid 1px var(--mist);
}

.bundle-table-container th:first-child,
.ecm-table-container th:first-child {
  border-left: solid 1px var(--mist);
}

.bundle-table-container td:first-child,
.ecm-table-container td:first-child {
  border-left: solid 1px var(--ghost);
}

.bundle-table-container td,
.ecm-table-container td {
  font-size: 16px;
  text-transform: none;
  padding: 0.5rem;
  border-bottom: solid 1px var(--ghost);
  border-right: solid 1px var(--ghost);
}

.bundle-table-container tr:last-child td,
.ecm-table-container tr:last-child td {
  border-bottom: 0;
}

.bundle-table-container tr:hover,
.ecm-table-container tr:hover {
  background-color: var(--ghost);
  cursor: pointer;
}

.bundle-table-container th:hover,
.ecm-table-container th:hover {
  background-color: var(--mist);
  cursor: pointer;
}

/* .ecm-table-container tr:focus {
  background-color: var(--berry);
} */

.table-header-sticky {
  position: sticky;
  top: 0;
}

.table-header-sort {
  display: flex;
  align-items: center;
}

.selected-row {
  font-weight: 600;
  background-color: var(--berry);
}

/* .selected-row:focus {
  background-color: var(--berry);
} */

.disabled-row {
  font-style: oblique;
  background-color: var(--mist);
}

.error-no-data-text {
  text-align: center;
  padding: 1rem;
  font-size: 16px;
  font-weight: 600;
  color: #011248;
}

.delete-ecm-row-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
}

.delete-ecm-row-button:hover {
  color: var(--error);
}

body {
  font-family: var(--sisa-font-family);
}

#sidebar {
  display: grid;
  grid-template-rows: 100px 1fr 140px;
  width: 256px;
  height: 100vh;
  padding: 6px;
  margin: 0;
  position: sticky;
  top: 0;
  left: 0;
  background-color: var(--jasmine);
}

#nav-icon-container {
  color: var(--indigo);
  gap: 0;
}

.logo-img {
  max-width: 160px;
}

.powered-by-sisa-logo {
  width: 170px;
}

.sidebar-account-menu {
  color: var(--indigo);
  gap: 0;
}

.sidebar-main-menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow: auto;
  padding: 6px;
}

.flexDirection {
  display: flex;
  flex-direction: column;
}

.user-name-icon {
  display: none;
}

.navItemComponentNoTier {
  display: flex;
  text-align: center;
  width: 100%;
  height: 50px;
  padding: 12px;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  cursor: pointer;
  text-transform: capitalize;
  pointer-events: none;
  color: rgb(167, 167, 167);
}

.navItemComponent {
  display: flex;
  place-items: center;
  width: 100%;
  height: 50px;
  padding: 12px;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  cursor: pointer;
  text-transform: capitalize;
  border-radius: 5px;
  color: var(--indigo);
}

.navItemComponent:hover {
  background: var(--ghost);
  color: var(--information);
}

.navItemComponent.active {
  color: var(--information);
}

.navItemComponent:focus {
  background: none;
  color: var(--information);
  border: 2px solid var(--information);
}

.navItemComponent:hover svg path,
.navItemComponent:focus svg path,
.navItemComponent.active svg path {
  fill: var(--information);
}

.nav-item-Icon-container {
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.nav-item-Icon {
  font-size: 22px;
}

.nav-item-Icon-offset_l {
  font-size: 22px;
  margin-left: -6px;
}

.nav-item-Icon-offset_r {
  font-size: 22px;
  margin-left: 6px;
}

.profile {
  display: flex;
  text-align: left;
  justify-content: flex-start;
  align-items: center;
  margin: 0.5rem;
  place-items: center;
  font-family: var(--sisa-font-family);
}

.profile-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: var(--emerald);
  font-size: 20px;
  color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  margin-right: 1rem;
  font-family: var(--sisa-font-family);
}

#logo-image {
  display: none;
}

.web-hide {
  display: none;
}

.tablet_layout {
  display: none;
}

.button_hamburger_menu {
  width: 80px;
}

.hamburger_menu_icon {
  font-size: 32px;
}

.sidebar-footer {
  border-top: 2px solid lightgray;
  margin: 6px 6px 24px 6px;
}

.sidebar-close-button {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.sidebar-close-icon {
  font-size: 20px;
  padding: 16px;
}

@media (max-width: 1023px) {
  .desktop_layout {
    display: none;
  }

  .tablet_layout {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  #sidebar {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: auto;
    justify-content: space-between;
    position: relative;
  }
}

/* file upload component css */
.buildingBox .dragDropContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid var(--black);
  width: 90%;
  height: 50%;
  border-radius: 10px;
}

.buildingBox .dragDropMiddleBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  height: 80%;
  border-top: 15px solid rgb(217, 217, 217, 0.2);
  border-bottom: 15px solid rgb(217, 217, 217, 0.2);
  border-left: 40px solid rgb(217, 217, 217, 0.2);
  border-right: 40px solid rgb(217, 217, 217, 0.2);
  border-radius: 5px;
}

.buildingBox .dragDropSmallBox {
  height: 80%;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-width: 2px;
  border-style: dashed;
  border-color: #cbd5e1;
  background-color: var(--white);

  color: #4f5052;
}

.buildingBox .dragDropSmallBox:hover {
  background-color: #f5f5f5;
}


.buildingBox .removeFile {
  height: 45px;
  width: 100px;
  margin: 10px;
  background-color: #ad3017;
}

.buildingBox .fileHandle {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 3vh;
}

.buildingBox .submitFile {
  height: 45px;
  width: 100px;
  margin: 10px;
  background-color: #2359a2;
}

.buildingBox .fileUploadInput {
  display: none;
}

.buildingBox .fileuploadlabel {
  cursor: pointer;
  color: #2359a2;
  text-transform: initial;
}

.buildingBox .uploadIcon {
  margin: 5px auto;
}

.buildingBox .tableDisplay {
  overflow: scroll;
  height: 60vh;
  width: 50vw;
  margin: 20px;
  border: 1px solid var(--black);
  border-radius: 5px;
}

.buildingBox .popup-container {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(101, 105, 117, 0.5);
}

.buildingBox .popup-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 30%;
  right: 30%;
  bottom: 60%;
  top: 20%;
  margin: auto;
  border-color: var(--black);
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 5px 10px;
}

.buildingBox .popup-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  margin: 10px;
  color: #1162cb;
}

.buildingBox .popup-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 50px;
  width: 80px;
  margin: 40px auto;
}

.buildingBox .dropdown {
  height: 3rem;
  border-radius: 8px;
  padding: 0.3em;
  margin: 15px;
  background-color: #3639e4;
  color: var(--white);
  text-align: center;
}

.buildingBox .dragDropSmallBox>.disbaleInput {
  color: #a6a8ab;
  font-size: 18px;
}


.buildingBox .resultOutput {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  padding: 12rem 2rem 2rem 2rem;
  width: 70%;
  height: auto;
  border-radius: 5px;
  border: 1px solid #a6a8ab;
  margin: 2rem;
  overflow: scroll;

}

.buildingBox .svgbtn {
  display: flex;
  position: absolute;
  top: 2rem;
  right: 2rem;
  cursor: pointer;
}

.buildingBox .svgbtn:hover {
  color: var(--white);
  background-color: var(--error);
}
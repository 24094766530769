.select {
  display: grid;
  position: relative;
  width: 100%;
  gap: 8px;
}
.select__label {
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  color: #141414;
}
.select__lable--default {
  color: #141414;
}
.select__label--disabled {
  color: #737373;
}
.select__label--required {
  font-weight: 700;
  color: #cf0a2e;
}
.select__container {
  display: flex;
  align-items: center;
  width: 100%;
}
.select__container__input {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
}
.select__container__input_buttons {
  display: flex;
  flex-direction: column;
  gap: 8px;
  cursor: pointer;
}
.select__container__input_buttons--save {
  color: #008611;
}
.select__container__input_buttons--cancel {
  color: #cf0a2e;
}
.select__icon {
  margin-left: 10px;
}
.select__field {
  cursor: pointer;
  appearance: none;
  border: 1px solid #737373;
  border-radius: 4px;
  height: 40px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding: 0px 32px 0px 16px;
  width: 100%;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='16' width='10' viewBox='0 0 320 512'%3E%3C!--!Font Awesome Pro 6.5.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc.--%3E%3Cpath fill='%23737373' d='M165.6 349.8c-1.4 1.3-3.5 2.2-5.6 2.2s-4.2-.8-5.6-2.2L34.2 236.3c-1.4-1.3-2.2-3.2-2.2-5.2c0-3.9 3.2-7.1 7.1-7.1l241.7 0c3.9 0 7.1 3.2 7.1 7.1c0 2-.8 3.8-2.2 5.2L165.6 349.8zm22 23.3L307.7 259.6c7.8-7.4 12.3-17.7 12.3-28.4c0-21.6-17.5-39.1-39.1-39.1L39.1 192C17.5 192 0 209.5 0 231.1c0 10.8 4.4 21.1 12.3 28.5L132.4 373.1c7.4 7 17.3 10.9 27.6 10.9s20.1-3.9 27.6-10.9z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 0.7rem top 50%;
  background-size: 0.7rem auto;
}
.select__field::placeholder {
  color: #737373;
}
.select__field[data-touched="true"]:valid {
  border: 2px solid #008611;
}
.select__field[data-touched="true"]:valid:focus {
  outline: none;
  box-shadow: 0px 0px 0px 2px rgba(0, 134, 17, 0.5);
}
.select__field[data-touched="true"]:invalid {
  border: 2px solid #f64968;
}
.select__field[data-touched="true"]:invalid:focus {
  outline: none;
  box-shadow: 0px 0px 0px 2px rgba(207, 10, 46, 0.5);
}
.select__field--default {
  color: #404040;
}
.select__field--default:focus {
  outline: none;
  box-shadow: 0px 0px 0px 2px #20c5ff;
}
.select__field--disabled {
  border: 1px solid #b3b3b3;
  background-color: rgba(230, 230, 230, 0.5);
  color: #b3b3b3;
}
.select__field--disabled:focus {
  outline: none;
}
.select__field--readonly {
  background-color: #e6e6e6;
}
.select__field--readonly:focus {
  outline: none;
}
.select__valication {
  position: absolute;
  top: 75px;
  left: 5px;
  font-size: 12px;
}
.select__validation--invalid {
  color: #cf0a2e;
}
.select__validation--valid {
  color: #008611;
}

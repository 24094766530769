.instructional-text__main-instructions {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;
  padding: 0.5rem 0;
  color: #141414;
}
.instructional-text__main-instructions-title {
  font: 600 1.125rem/2rem Work Sans;
}
.instructional-text__main-instructions-p {
  font: 400 1rem/1.75rem Work Sans;
  width: 100%;
}
.instructional-text__additional-instructions {
  max-width: 100%;
  margin: 12px 0;
  padding: 12px 24px;
  color: #404040;
  background-color: #e6e6e6;
  border-radius: 10px;
}
.instructional-text__additional-instructions-title {
  font: 600 16px/20px Work Sans;
}
.instructional-text__additional-instructions-p {
  font: 400 14px/18px Work Sans;
  width: 100%;
}
.bold_text {
  font: 600 14px/18px Work Sans;
}

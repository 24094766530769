.navigationTab {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.25rem;
  padding: 0.25rem 0.75rem;
  border-radius: 0.25rem 0.25rem 0rem 0rem;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  border-left: 2px solid #fff;
  background: rgba(249, 249, 249, 0.5);
  color: #224bff;
  cursor: pointer;
  text-wrap: nowrap;
  font: 500 0.75rem/1.25rem Work sans;
}
.navigationTab.selected {
  background: #f9f9f9;
  color: #011148;
}
.navigationTab:hover {
  color: #2e45be;
  background: #e6e6e6;
}
.navigationTab:focus {
  color: #224bff;
  transition: box-shadow 0.3s ease;
  outline: none;
}
.navigationTab.disabled {
  color: #737373;
  background: #f9f9f9;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  border-left: 2px solid #fff;
  cursor: not-allowed;
}
.navigationTab__dropdown {
  overflow: hidden;
  display: flex;
  padding: 0.5rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.125rem;
  position: absolute;
  border-radius: 0.25rem;
  background: #fff;
  box-shadow: 2px 2px 2px 0px rgba(20, 20, 20, 0.15);
  z-index: 1;
}
.navigationTab__dropdown .navigationTab-mobile__small {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.25rem;
  padding: 0.25rem 0.75rem;
  border-radius: 0.25rem 0.25rem 0rem 0rem;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  border-left: 2px solid #fff;
  background: rgba(249, 249, 249, 0.5);
  color: #224bff;
  cursor: pointer;
  text-wrap: nowrap;
  font: 500 0.75rem/1.25rem Work sans;
  padding: 0.625rem 0.75rem 0.75rem 0.75rem;
}
.navigationTab__dropdown .navigationTab-mobile__small.selected {
  background: #f9f9f9;
  color: #011148;
}
.navigationTab__dropdown .navigationTab-mobile__small:hover {
  color: #2e45be;
  background: #e6e6e6;
}
.navigationTab__dropdown .navigationTab-mobile__small:focus {
  color: #224bff;
  transition: box-shadow 0.3s ease;
  outline: none;
}
.navigationTab__dropdown .navigationTab-mobile__small.disabled {
  color: #737373;
  background: #f9f9f9;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  border-left: 2px solid #fff;
  cursor: not-allowed;
}
@media (min-width: 320px) and (max-width: 480px) {
  .navigationTab__dropdown {
    width: 12.5rem;
    top: 17rem;
    right: 1.7rem;
    font: 500 0.75rem/1.25rem Work sans;
  }
}
@media (min-width: 480px) and (max-width: 768px) {
  .navigationTab__dropdown {
    width: 11.25rem;
    top: 17rem;
    right: 3.5rem;
  }
}

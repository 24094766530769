.page_title {
  font-size: 28px;
  line-height: 36px;
  font-weight: 600;
  color: #011248;
}

.page_subtitle {
  padding: 1rem;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #011248;
}

.page_subheading {
  align-items: center;
  display: flex;
  padding: 0.5rem;
  column-gap: 10px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}

.page_innerheading {
  align-items: center;
  display: flex;
  padding: 0;
  column-gap: 10px;
  font-weight: 600;
}

.page_text {
  margin: 1rem 0;
  padding: 0.5rem 1rem;
  background-color: hsl(0, 0%, 95%);
  border-radius: 6px;
  line-height: 24px;
  font-size: 16px;
  color: var(--charcoal);
}

.page_container_margin {
  /* margin: 0.5rem 1rem; */
}

.page_no_data_overlay {
  display: flex;
  font-weight: 600;
  font-size: 18px;
  width: 100%;
  height: 6vh;
  border-radius: 5px;
  border: solid 2px skyblue;
  background-color: hsl(203, 92%, 95%);
  align-items: center;
  justify-content: center;
  color: #112e4b;
}

.hr_break {
  margin: 1rem 0;
}

.input__fields {
  display: grid;
  grid-gap: 32px;
}

.input__row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
}

.horizontal__line {
  width: 100%;
  height: 1px;
  background-color: #4d4d4d;
  margin: 10px;
}

.account-container {
  gap: 2.3rem;
  font: 500 1rem var(--sisa-font-family);
  display: flex;
  align-items: flex-start;
  width: 100%;
}

/* main container for all */
.tabContainer {
  width: 100%;
  padding: 1.5rem;
  align-items: center;
  border: 1px solid var(--ghost);
}

.desktop-box {
  width: 75%;
  height: 100%;
}

/* account info page */
.user-info {
  gap: 1rem;
}

.user-info > div {
  color: var(--indigo);
  padding: 0 1rem 0 0;
}

.user-info > div > p {
  margin-top: 0.5rem;
  color: var(--charcoal);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* 3 pages */
.user-info,
.editProfile,
.pswdBox {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.user-info > div,
.editProfile > .textFieldContainer,
.pswdBox > .textFieldContainer {
  flex-basis: calc(33.33% - 1.5rem);
  max-width: calc(33.33% - 1.5rem);
  margin-bottom: 1rem;
}

.editProfile>.textFieldContainer>label,
.pswdBox>.textFieldContainer>label {
  font: 500 1rem var(--sisa-font-family);
  color: var(--indigo);
}

.editProfile > .textFieldContainer input,
.textFieldContainer input {
  width: 100%;
  height: 2.5rem;
  border-radius: 0.25rem;
  border: 1px solid var(--mist);
  background: var(--white);
  padding: 0.5rem;
  margin-top: 0.5rem;
}

/* sidebar */
.account-tabs {
  background-color: var(--jasmine);
  width: 25%;
  min-width: 14rem;
  /* height: 16.3rem; */
  height: 100%;
  margin-top: 3.7rem;
  padding: 1rem 1rem 2rem 1rem;
  color: var(--indigo);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.account-border {
  /* padding-top: 1.2rem; */
  margin-top: 1rem;
  width: 100%;
  border-top: 1px solid var(--mist);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
}

.tabs-header {
  font: 600 20px var(--sisa-font-family);
  text-align: center;
  padding-top: 0.5rem;
}

.tabs-icons {
  width: 1.75rem;
  height: 1.625rem;
}

.active_menu {
  display: none;
}

.account-sidebar__icon {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  padding: 0.75rem 1.5rem;
  width: 100%;
  height: 100%;
  cursor: pointer;
  font: 500 16px var(--sisa-font-family);
}

.account-sidebar__icon:active {
  color: var(--information);
}

.account-sidebar__icon:focus {
  background-color: var(--ghost);
  border: 2px solid var(--information);
  border-radius: 0.25rem;
  padding: 0.75rem 1.5rem;
  width: 14rem;
  color: var(--information);
}

.account-sidebar__icon:hover {
  background-color: var(--ghost);
  color: var(--information);
  border-radius: 0.25rem;
}

.account-sidebar__icon:disabled {
  color: var(--ash);
  cursor: not-allowed;
}

.account-sidebar__icon--active {
  background-color: var(--ghost);
  border:2px solid var(--information);
  border-radius: 0.25rem;
  padding: 0.75rem 1.5rem;
  width: 14rem;
  height: 53px;
  display: flex;
  flex-direction: row;
  background-color: var(--ghost);
  color: var(--information);
  border-radius: 0.25rem;
}

/* buttons */
.editButton p {
  background-color: #03045e;
  inline-size: 130px;
  padding-left: 1rem;
}

.editButton p {
  background-color: #03045e;
  inline-size: 130px;
  padding-left: 1rem;
}

.editButton p li {
  color: var(--white);
}

/* sidebar icons and nav etc */
ul.nav {
  display: inline-block;
  /* display: table; */
  /* padding-bottom: 18px; */
}

.nav > div {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

/* new ui btn */
.account-btn-box {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 1rem;
  /* margin: 1rem; */
  width: 100%;
  margin: 1rem 0 0.5rem 0;
}

.account-edit-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  width: 8.7rem;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  background-color: var(--information);
  color: var(--jasmine);
  margin-top: 1rem;
  margin-inline-start: auto;
  font: 600 1rem var(--sisa-font-family);
  list-style: none;
  text-align: center;
}

.account-save-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  width: 8.7rem;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  font: 600 1rem var(--sisa-font-family);
  color: var(--jasmine);
  background-color: var(--information);
}

.account-edit-btn:active,
.account-save-btn:active,
.account-edit-btn:hover,
.account-save-btn:hover {
  background-color: var(--hover);
  cursor: pointer;
  color: var(--jasmine);
}

.account-edit-btn:focus,
.account-save-btn:focus {
  background-color: var(--information);
  border: 2px solid #20c5ff;
  align-items: center;
}

/* cancel btn */
.account-cancel-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  width: 8.7rem;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  background-color: var(--jasmine);
  color: var(--information);
  border: 1px solid var(--information);
  font: 600 16px var(--sisa-font-family);
  list-style: none;
}

.account-cancel-btn:active,
.account-cancel-btn:hover {
  background-color: var(--information);
  color: var(--jasmine);
  cursor: pointer;
}

.account-cancel-btn:focus {
  background-color: var(--jasmine);
  color: var(--information);
  border: 2px solid #20c5ff;
  transition: box-shadow 0.3s ease;
  outline: none;
}

.error-message {
  color: var(--error);
  font: 500 14px var(--sisa-font-family);
  text-align: center;
}

#account-nav {
  margin-top: 1.3rem;
}

@media (max-width: 980px) {
  .account-tabs {
   min-width: 14rem;
  }
}

@media (max-width: 768px) {
  .account-container {
    flex-direction: column-reverse;
    align-items: center;
    width: 100%;
    gap: 0;
  }

  .account-tabs {
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
  }

  .desktop-box {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .TabContent {
    width: 100%;
  }

  .tabContainer,
  .pswdBox,
  .user-info,
  .editProfile {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .tabContainer,
  .pswdBox > .textFieldContainer {
    width: 100%;
    height: auto;
  }

  .pswdBox > .textFieldContainer > label {
    display: flex;
  }

  .user-info {
    gap: 0;
  }

  .user-info > div,
  .editProfile > .textFieldContainer,
  .pswdBox > .textFieldContainer {
    flex-basis: auto;
    max-width: none;
    margin-bottom: 1rem;
  }

  .textFieldContainer input {
    width: auto;
  }

  .active-section {
    display: none;
  }

  .hambuger-icon {
    display: block;
    position: absolute;
    right: 32px;
    font-size: 30px;
  }

  .nav-ul {
    width: 100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  #tab-container {
    flex-direction: column;
  }

  .account-main-header {
    color: var(--jasmine);
  }

  .title {
    display: flex;
    flex-direction: column;
  }
}

.powerbi_outer_container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  width: 100%;
}
.powerbi_login_menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0 1rem;
  gap: 1rem;
}
.powerbi_report_container {
  height: 100%;
  width: 100%;
}
.powerbi_workspace_report_menu {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  column-gap: 2rem;
  margin: 0 0 1rem 0;
}
.powerbi_admin_menu {
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
}
.button_container {
  height: 100%;
  display: flex;
  align-items: flex-end;
}
.text_box {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  gap: 0.5rem;
}
.text_box__header {
  display: flex;
}
.text_box__header__text {
  font-size: 1rem;
  font-weight: 500;
}
.text_box__body {
  display: flex;
  align-items: center;
  height: 2.5rem;
  border: 1px solid #b3b3b3;
  border-radius: 5px;
  background-color: #e6e6e6;
}
.text_box__body__text {
  padding: 0 1rem;
}

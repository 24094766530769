.icon-box {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 20vw;
  height: 9rem;
  background-color: transparent;
  border-radius: 4px;
  cursor: pointer;
}

.icon-box:hover {
  transform: scale(1.05);

}

.decarb-icon {
  border: 3px solid var(--information);
  color: var(--information);
}

.budget-icon {
  border: 3px solid var(--success);
  color: var(--success);
}

.bundle-icon {
  border: 3px solid var(--alert);
  color: var(--alert);
}

.icon-menu {
  font-size: 50pt;
}

.icon-text {
  font-size: 18px;
  font-weight: 700;
  margin-top: 0.8rem;
}

.box-menu-icon {
  font-size: 60pt;
}

.box-menu-text {
  font-size: 18pt;
  font-weight: 600;
}

.upgrade-restrict-text {
  padding: 0 0.5em;
  font-size: 10pt;
}

.analysis-page-container{
  display: flex;
  justify-content: space-evenly;
  margin: 50px;
  gap:2rem;
}

@media (max-width: 768px) {
  .analysis-page-container{
    display: grid;
    grid-template-columns: 1fr;
    gap:2rem;
    align-items: center;
    justify-content: center;
    margin: 10px auto;
  }

  .icon-box {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 70vw;
    height: 9rem;
    background-color: transparent;
    border-radius: 4px;
    cursor: pointer;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .icon-box {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 25vw;
    height: 9rem;
    background-color: transparent;
    border-radius: 4px;
    cursor: pointer;
  }
}

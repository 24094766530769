  /* Go top button */
  .go_top {
      width: 40px;
      height: 40px;
      position: fixed;
      background: #333;
      color: var(--white);
      right: 50px;
      bottom: 50px;
      text-align: center;
      line-height: 40px;
      display: none;
      transition: opacity .5s;
  }

  .fade-out {
      opacity: 0;
  }

  /* @media (min-width: 768px) and (max-width: 1024px) {
      .title {
          background-color: #03045E;
      }

      .title>h1 {

          color: var(--indigo);
          margin-left: auto;
          margin-right: auto;

      }
  } */


  @media (max-width: 768px) {
      .table-wrap {
          width: 100%;
      }

      .table-wrap>div {
          width: 100%;
      }

      .home-component {
          width: 100%;
      }

      .go_top {
          display: block;
      }

      #home-container {
          scroll-margin-top: 60px;
      }

  }

.header__line {
  height: 1.5px;
  width: 100%;
  align-self: stretch;
  background-color: #011148;
  margin: 24px 0;
}
.form__container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
}
.form-title-button__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.form__title {
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  color: #011248;
}
.form__content {
  display: flex;
  position: relative;
  flex-direction: column;
  background-color: #fff;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  padding: 32px 24px;
  gap: 32px;
}
@media (max-width: 767px) {
  .form__content {
    padding: 16px;
  }
}
.form__inputs__container {
  display: flex;
  width: 100%;
  gap: 24px;
  flex-wrap: wrap;
}
.form__description {
  color: #141414;
}
.basicInfo-instruction__container {
  padding: 0;
  margin-bottom: 1rem;
}
.additional_p {
  padding-top: 1rem;
}
.page__header {
  font-size: 28px;
  line-height: 36px;
  font-weight: 600;
  color: #011148;
}
.page__subheader {
  align-items: center;
  display: flex;
  column-gap: 10px;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
}
.landing__tabs {
  width: 10.5625rem;
  padding: 0.75rem 1rem 1rem 1rem;
}
.landing__button {
  display: none;
}
@media (min-width: 320px) and (max-width: 479px) {
  .landing__tabs {
    width: 100%;
    padding: 0.625rem 0.75rem 0.75rem 0.75rem;
  }
}
.form__buttons-validation {
  display: flex;
  position: absolute;
  top: 180px;
  right: 56px;
}
@media (min-width: 768px) and (max-width: 1023px) {
  .form__buttons-validation {
    top: 265px;
    right: 56px;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .form__buttons-validation {
    justify-content: center;
    top: 350px;
    right: 56px;
    left: 56px;
    width: auto;
  }
}
@media (max-width: 479px) {
  .form__buttons-validation {
    justify-content: center;
    top: 350px;
    right: 40px;
    left: 40px;
    width: auto;
  }
}
.form__buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
}
@media (max-width: 767px) {
  .form__buttons {
    display: grid;
    grid-template-columns: 1fr;
    gap: 16px;
  }
}
.form__buttons__left {
  display: grid;
  gap: 16px;
  grid-template-columns: 150px 150px;
  justify-content: start;
}
@media (max-width: 767px) {
  .form__buttons__left {
    grid-template-columns: 1fr;
    gap: 16px;
  }
}
.form__buttons__right {
  display: grid;
  gap: 16px;
  grid-template-columns: 1fr 1fr;
  justify-content: end;
}
@media (max-width: 767px) {
  .form__buttons__right {
    grid-template-columns: 1fr;
    gap: 16px;
  }
}
.data-collection__main-container {
  background-color: #f9f9f9;
  padding: 24px;
}
@media (max-width: 767px) {
  .data-collection__main-container {
    padding: 16px;
  }
}
@media (max-width: 767px) {
  .data-collection__main-container #back-button-left {
    display: none;
  }
}
@media (min-width: 768px) {
  .data-collection__main-container #back-button-right {
    visibility: hidden;
  }
}
@media (max-width: 767px) {
  .data-collection__main-container .complete-button-upper {
    display: none;
  }
}
@media (min-width: 768px) {
  .data-collection__main-container .complete-button-lower {
    display: none;
  }
}
#site-name {
  grid-area: item1;
}
#street-address {
  grid-area: item2;
}
#city {
  grid-area: item3;
}
#province {
  grid-area: item4;
}
#county {
  grid-area: item5;
}
#postal-code {
  grid-area: item6;
}
#owner-organization {
  grid-area: item7;
}
#office-address {
  grid-area: item8;
}
#applicant-name {
  grid-area: item9;
}
#telephone {
  grid-area: item10;
}
#horizontal-light {
  grid-area: item11;
}
#program-job-number {
  grid-area: item12;
}
#program-select {
  grid-area: item13;
}
#other-program {
  grid-area: item14;
}
#site-contact-name {
  grid-area: item15;
}
#site-auditors {
  grid-area: item16;
}
#site-visit-date {
  grid-area: item17;
}
#responsible-project-engineer {
  grid-area: item18;
}
#email-of-responsible-engineer {
  grid-area: item19;
}
.base-information-form__inputs {
  display: grid;
  grid-column-gap: 24px;
  grid-row-gap: 32px;
  grid-template-columns: 1fr;
  grid-template-areas: "item1" "item2" "item3" "item4" "item5" "item6" "item7" "item8" "item9" "item10" "item11" "item12" "item13" "item14" "item15" "item16" "item17" "item18" "item19";
}
@media (min-width: 769px) and (max-width: 1024px) {
  .base-information-form__inputs {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: "item1 item2" "item3 item4" "item5 item6" "item7 item8" "item9 item10" "item11 item11" "item12 item13" "item14 item15" "item16 item17" "item18 item19";
  }
}
@media (min-width: 1025px) and (max-width: 1420px) {
  .base-information-form__inputs {
    grid-template-columns: repeat(6, 1fr);
    grid-template-areas: "item1 item1 item1 item2 item2 item2" "item3 item3 item3 item4 item4 item4" "item5 item5 item5 item6 item6 item6" "item7 item7 item7 item8 item8 item8" "item9 item9 item9 item10 item10 item10" "item11 item11 item11 item11 item11 item11" "item12 item12 item13 item13 item14 item14" "item15 item15 item16 item16 item17 item17" "item18 item18 item18 item19 item19 item19";
  }
}
@media (min-width: 1421px) and (max-width: 1920px) {
  .base-information-form__inputs {
    grid-template-columns: repeat(6, 1fr);
    grid-template-areas: "item1 item1 item2 item2 item3 item3" "item4 item4 item5 item5 item6 item6" "item7 item7 item7 item8 item8 item8" "item9 item9 item9 item10 item10 item10" "item11 item11 item11 item11 item11 item11" "item12 item12 item13 item13 item14 item14" "item15 item15 item16 item16 item17 item17" "item18 item18 item18 item19 item19 item19";
  }
}
@media (min-width: 1921px) and (max-width: 2559px) {
  .base-information-form__inputs {
    grid-template-columns: repeat(12, 1fr);
    grid-template-areas: "item1 item1 item1 item2 item2 item2 item3 item3 item3 item4 item4 item4" "item5 item5 item5 item6 item6 item6 item7 item7 item7 item8 item8 item8" "item9 item9 item9 item10 item10 item10 . . . . . ." "item11 item11 item11 item11 item11 item11 item11 item11 item11 item11 item11 item11" "item12 item12 item12 item12 item13 item13 item13 item13 item14 item14 item14 item14" "item15 item15 item15 item15 item16 item16 item16 item16 item17 item17 item17 item17" "item18 item18 item18 item18 item19 item19 item19 item19 . . . .";
  }
}
@media (min-width: 2561px) {
  .base-information-form__inputs {
    grid-template-columns: repeat(5, 1fr);
    grid-template-areas: "item1 item2 item3 item4 item5" "item6 item7 item8 item9 item10" "item11 item11 item11 item11 item11" "item12 item13 item14 item15 item16" "item17 item18 item19 . .";
  }
}
#horizontal__line--1,
#horizontal__line--2,
#horizontal__line--3 {
  width: 100%;
  height: 1px;
  background-color: #4d4d4d;
  margin: 10px;
}
#property-type {
  grid-area: input1;
}
#year-built {
  grid-area: input2;
}
#last-remodeling-year {
  grid-area: input3;
}
#gross-floor-area {
  grid-area: input4;
}
#no-of-elevators {
  grid-area: input5;
}
#no-of-floors {
  grid-area: input6;
}
#no-of-studios {
  grid-area: input7;
}
#no-of-1-bedroom {
  grid-area: input8;
}
#no-of-2-bedroom {
  grid-area: input9;
}
#no-of-3-bedroom {
  grid-area: input10;
}
#horizontal__line--1 {
  grid-area: input11;
}
#total-no-of-suites {
  grid-area: input12;
}
#avg-bachelor-area {
  grid-area: input13;
}
#avg-1-bedroom-area {
  grid-area: input14;
}
#avg-2-bedroom-area {
  grid-area: input15;
}
#avg-3-bedroom-area {
  grid-area: input16;
}
#horizontal__line--2 {
  grid-area: input17;
}
#total-avg-suite-area {
  grid-area: input18;
}
#no-of-workers {
  grid-area: input19;
}
#no-of-residents {
  grid-area: input20;
}
#total-no-of-people {
  grid-area: input21;
}
#tennants-pay-for-electricity {
  grid-area: input22;
}
#property-has-cooling {
  grid-area: input23;
}
#horizontal__line--3 {
  grid-area: input24;
}
.property-usage-form__inputs {
  display: grid;
  grid-column-gap: 24px;
  grid-row-gap: 32px;
  grid-template-columns: 1fr;
  grid-template-areas: "input1" "input2" "input3" "input4" "input5" "input6" "input7" "input8" "input9" "input10" "input12" "input13" "input14" "input15" "input16" "input18" "input19" "input20" "input21" "input22" "input23";
}
@media (min-width: 768px) and (max-width: 1024px) {
  .property-usage-form__inputs {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: "input1 input2" "input3 input4" "input5 input6" "input7 input8" "input9 input10" "input11 input12" "input13 input14" "input15 input16" "input17 input18" "input19 input20" "input24 input21 " "input22 input23";
  }
}
@media (min-width: 1025px) and (max-width: 1420px) {
  .property-usage-form__inputs {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: "input1 input2" "input3 input4" "input5 input6" "input7 input8" "input9 input10" "input11 input12" "input13 input14" "input15 input16" "input17 input18" "input19 input20" "input24 input21 " "input22 input23";
  }
  .property-usage-form__inputs #horizontal__line--3 {
    display: block;
  }
}
@media (min-width: 1421px) and (max-width: 1920px) {
  .property-usage-form__inputs {
    grid-template-columns: repeat(12, 1fr);
    grid-template-areas: "input1 input1 input1 input1 input2 input2 input2 input2 input3 input3 input3 input3" "input4 input4 input4 input4 input5 input5 input5 input5 input6 input6 input6 input6" "input7 input7 input7 input8 input8 input8 input9 input9 input9 input10 input10 input10" "input11 input11 input11 input11 input11 input11 input11 input11 input11 input12 input12 input12" "input13 input13 input13 input14 input14 input14 input15 input15 input15 input16 input16 input16" "input17 input17 input17 input17 input17 input17 input17 input17 input17 input18 input18 input18" "input19 input19 input19 input19 input20 input20 input20 input20 input21 input21 input21 input21" "input22 input22 input22 input22 input22 input22 input23 input23 input23 input23 input23 input23";
  }
  .property-usage-form__inputs #horizontal__line--3 {
    display: none;
  }
}
@media (min-width: 1921px) and (max-width: 2560px) {
  .property-usage-form__inputs {
    grid-template-columns: repeat(15, 1fr);
    grid-template-areas: "input1 input1 input1 input1 input1 input2 input2 input2 input2 input2 input3 input3 input3 input3 input3" "input4 input4 input4 input4 input4 input5 input5 input5 input5 input5 input6 input6 input6 input6 input6" "input7  input7 input7 input8 input8 input8 input9 input9 input9 input10 input10 input10 input12 input12 input12" "input13 input13 input13 input14 input14 input14 input15 input15 input15 input16 input16 input16 input18 input18 input18" "input19 input19 input19 input20 input20 input20 input21 input21 input21 input22 input22 input22 input23 input23 input23";
  }
  .property-usage-form__inputs #horizontal__line--3 {
    display: none;
  }
  .property-usage-form__inputs #horizontal__line--1,
  .property-usage-form__inputs #horizontal__line--2 {
    display: none;
  }
}
@media (min-width: 2561px) {
  .property-usage-form__inputs {
    grid-template-columns: repeat(20, 1fr);
    grid-template-areas: "input1 input1 input1 input1 input1 input2 input2 input2 input2 input2 input3 input3 input3 input3 input3 input4 input4 input4 input4 input4" "input5 input5 input5 input5 input5 input6 input6 input6 input6 input6 . . . . . . . . . ." "input7  input7 input7 input7 input8 input8 input8 input8 input9 input9 input9 input9 input10 input10 input10 input10 input12 input12 input12 input12" "input13 input13 input13 input13 input14 input14 input14 input14 input15 input15 input15 input15 input16 input16 input16 input16 input18 input18 input18 input18" "input19 input19 input19 input19 input20 input20 input20 input20 input21 input21 input21 input21 input22 input22 input22 input22 input23 input23 input23 input23";
  }
  .property-usage-form__inputs #horizontal__line--1,
  .property-usage-form__inputs #horizontal__line--2,
  .property-usage-form__inputs #horizontal__line--3 {
    display: none;
  }
}
#technology-select {
  grid-area: box1;
}
#area-served {
  grid-area: box2;
}
#space-description {
  grid-area: box3;
}
#water-per-lamp {
  grid-area: box4;
}
#no-of-lamps-per-fixture {
  grid-area: box5;
}
#no-of-fixtures {
  grid-area: box6;
}
#lights-on-time {
  grid-area: box7;
}
#control-type {
  grid-area: box8;
}
#control-comments {
  grid-area: box9;
}
.lights-form__inputs {
  display: grid;
  margin-bottom: 32px;
  grid-column-gap: 24px;
  grid-row-gap: 32px;
  grid-template-columns: 1fr;
  grid-template-areas: "box1" "box2" "box3" "box4" "box5" "box6" "box7" "box8" "box9";
}
@media (min-width: 768px) and (max-width: 1024px) {
  .lights-form__inputs {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: "box1 box2" "box3 box4" "box5 box6" "box7 box8" "box9 box9";
  }
}
@media (min-width: 1025px) and (max-width: 1420px) {
  .lights-form__inputs {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: "box1 box2" "box3 box4" "box5 box6" "box7 box8" "box9 box9";
  }
}
@media (min-width: 1421px) and (max-width: 1920px) {
  .lights-form__inputs {
    grid-template-columns: repeat(12, 1fr);
    grid-template-areas: "box1 box1 box1 box1 box2 box2 box2 box2 box3 box3 box3 box3" "box4 box4 box4 box5 box5 box5 box6 box6 box6 box7 box7 box7" "box8 box8 box8 box8 box8 box8 box9 box9 box9 box9 box9 box9";
  }
}
@media (min-width: 1921px) and (max-width: 2560px) {
  .lights-form__inputs {
    grid-template-columns: repeat(20, 1fr);
    grid-template-areas: "box1 box1 box1 box1 box1 box2 box2 box2 box2 box2 box3 box3 box3 box3 box3 box4 box4 box4 box4 box4" "box5 box5 box5 box5 box5 box6 box6 box6 box6 box6 box7 box7 box7 box7 box7 box8 box8 box8 box8 box8" "box9 box9 box9 box9 box9 . . . . . . . . . . . . . . .";
  }
}
@media (min-width: 2561px) {
  .lights-form__inputs {
    grid-template-columns: repeat(20, 1fr);
    grid-template-areas: "box1 box1 box1 box1 box2 box2 box2 box2 box3 box3 box3 box3 box4 box4 box4 box4 box5 box5 box5 box5" "box6 box6 box6 box6 box7 box7 box7 box7 box8 box8 box8 box8 box9 box9 box9 box9 . . . .";
  }
}
#kitchen-sinks {
  grid-area: bin1;
}
#lavatories {
  grid-area: bin2;
}
#showers {
  grid-area: bin3;
}
#toilets {
  grid-area: bin4;
}
#urinals {
  grid-area: bin5;
}
#other {
  grid-area: bin6;
}
.water-fixtures-form__inputs {
  display: grid;
  grid-column-gap: 24px;
  grid-row-gap: 32px;
  grid-template-columns: 1fr;
  grid-template-areas: "bin1" "bin2" "bin3" "bin4" "bin5" "bin6";
}
@media (min-width: 769px) and (max-width: 1024px) {
  .water-fixtures-form__inputs {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: "bin1 bin2" "bin3 bin4" "bin5 bin6";
  }
}
@media (min-width: 1025px) and (max-width: 1420px) {
  .water-fixtures-form__inputs {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: "bin1 bin2" "bin3 bin4" "bin5 bin6";
  }
}
@media (min-width: 1421px) and (max-width: 1920px) {
  .water-fixtures-form__inputs {
    grid-template-columns: repeat(3, 1fr);
    grid-template-areas: "bin1 bin2 bin3 " "bin4 bin5 bin6 ";
  }
}
@media (min-width: 1921px) and (max-width: 2560px) {
  .water-fixtures-form__inputs {
    grid-template-columns: repeat(4, 1fr);
    grid-template-areas: "bin1 bin2 bin3 bin4" "bin5 bin6 . .";
  }
}
@media (min-width: 2561px) {
  .water-fixtures-form__inputs {
    grid-template-columns: repeat(4, 1fr);
    grid-template-areas: "bin1 bin2 bin3 bin4" "bin5 bin6 . .";
  }
}
.water-meter-bill__content {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.add-bills-content {
  display: flex;
  flex-direction: column;
  gap: 32px;
}
.water-meter-form__inputs {
  display: grid;
  margin-bottom: 32px;
  grid-column-gap: 24px;
  grid-row-gap: 32px;
  grid-template-columns: repeat(2, 1fr);
}
.water-bills-form__inputs {
  display: grid;
  margin-bottom: 32px;
  grid-row-gap: 32px;
}
.input__row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
}
#construction-type {
  grid-area: unit1;
}
#no-of-exterior-doors {
  grid-area: unit2;
}
#is-parkade-ceiling-insulated {
  grid-area: unit3;
}
#parkade-ceiling-r-factor {
  grid-area: unit4;
}
#is-roof-insulated {
  grid-area: unit5;
}
#roof-r-factor {
  grid-area: unit6;
}
#walls-r-factor {
  grid-area: unit7;
}
#windows-type {
  grid-area: unit8;
}
#windows-coverage {
  grid-area: unit9;
}
#windows-u-factor {
  grid-area: unit10;
}
#windows-draftyness {
  grid-area: unit11;
}
#general-comment {
  grid-area: unit12;
}
.envelope-form__inputs {
  display: grid;
  grid-column-gap: 24px;
  grid-row-gap: 32px;
  grid-template-columns: 1fr;
  grid-template-areas: "unit1" "unit2" "unit3" "unit4" "unit5" "unit6" "unit7" "unit8" "unit9" "unit10" "unit11" "unit12" "unit13";
}
@media (min-width: 769px) and (max-width: 1024px) {
  .envelope-form__inputs {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: "unit1 unit2" "unit3 unit4" "unit5 unit6" "unit7 unit8" "unit9 unit10" "unit11 unit12" "unit13 .";
  }
}
@media (min-width: 1025px) and (max-width: 1420px) {
  .envelope-form__inputs {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas: "unit1 unit2" "unit3 unit4" "unit5 unit6" "unit7 unit8" "unit9 unit10" "unit11 unit12" "unit13 .";
  }
}
@media (min-width: 1421px) and (max-width: 1920px) {
  .envelope-form__inputs {
    grid-template-columns: repeat(6, 1fr);
    grid-template-areas: "unit1 unit1 unit1 unit2 unit2 unit2" "unit3 unit3 unit3 unit4 unit4 unit4" "unit5 unit5 unit6 unit6 unit7 unit7" "unit8 unit8 unit8 unit9 unit9 unit9" "unit10 unit10 unit10 unit11 unit11 unit11" "unit12 unit12 unit12 unit13 unit13 unit13";
  }
}
@media (min-width: 1921px) and (max-width: 2560px) {
  .envelope-form__inputs {
    grid-template-columns: repeat(4, 1fr);
    grid-template-areas: "unit1 unit2 unit3 unit4" "unit5 unit6 unit7 unit8" "unit9 unit10 unit11 unit12" "unit13 . . .";
  }
}
@media (min-width: 2561px) {
  .envelope-form__inputs {
    grid-template-columns: repeat(20, 1fr);
    grid-template-areas: "unit1 unit1 unit1 unit1 unit2 unit2 unit2 unit2 unit3 unit3 unit3 unit3 unit4 unit4 unit4 unit4 unit5 unit5 unit5 unit5" "unit6 unit6 unit6 unit6 unit6 unit7 unit7 unit7 unit7 unit7 unit8 unit8 unit8 unit8 unit8 unit9 unit9 unit9 unit9 unit9" "unit10 unit10 unit10 unit10 unit10 unit11 unit11 unit11 unit11 unit11 unit12 unit12 unit12 unit12 unit12 unit13 unit13 unit13 unit13 unit13";
  }
}
#landing-page {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
#landing-page .material-symbols-outlined {
  font-size: 1rem;
}
#landing-page #no-saved-properties {
  display: flex;
  height: 40px;
  padding: 12px 8px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border: 1px solid #b3b3b3;
  background: #fff;
}
#landing-page #no-saved-properties #no-saved-properties-text {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
}
#landing-page .draft-edit-button {
  cursor: pointer;
  color: #224bff;
  line-height: 1rem;
}
#landing-page .draft-edit-button:hover {
  color: hsl(226, 74%, 68%);
}
#landing-page .draft-delete-button {
  cursor: pointer;
  color: #cf0a2e;
  line-height: 1rem;
}
#landing-page .draft-delete-button:hover {
  color: hsl(0, 74%, 68%);
  background-color: transparent;
}
.input_width--full {
  flex: 1 0 100%;
}
.input_width--1-2 {
  flex: 1 0 calc(50% - 48px);
}
.input_width--1-3 {
  flex: 1 0 calc(33% - 24px);
}
.input_width--2-3 {
  flex: 2 0 calc(66% - 24px);
}
.input_width--1-4 {
  flex: 1 0 calc(25% - 24px);
}
.input_width--3-4 {
  flex: 3 0 calc(75% - 24px);
}
@media screen and (max-width: 768px) {
  .input_width--1-2,
  .input_width--1-3,
  .input_width--2-3,
  .input_width--1-4,
  .input_width--3-4 {
    flex: 1 0 100%;
  }
}
@media screen and (min-width: 769px) and (max-width: 1420px) {
  .input_width--1-2,
  .input_width--1-3,
  .input_width--2-3,
  .input_width--1-4,
  .input_width--3-4 {
    flex: 1 0 calc(50% - 24px);
  }
}

#equipments-landing-page .form__buttons__right {
  grid-template-columns: auto;
  margin-top: 0;
}
#equipments-landing-page .page_no_data_overlay {
  display: flex;
  height: 40px;
  padding: 12px 8px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border: 1px solid #b3b3b3;
  background: #fff;
}
#equipments-landing-page .page_no_data_overlay p {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
  font-size: 12px;
}
#equipments-landing-page .material-symbols-outlined {
  font-size: 1.04463rem;
}
#equipments-landing-page .draft-edit-button {
  cursor: pointer;
  color: #224bff;
  font-family: Work Sans;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem;
}
#equipments-landing-page .draft-edit-button:hover {
  color: hsl(226, 74%, 68%);
}
#equipments-landing-page .draft-delete-button {
  cursor: pointer;
  color: #cf0a2e;
  font-family: Work Sans;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem;
}
#equipments-landing-page .draft-delete-button:hover {
  color: hsl(0, 74%, 68%);
  background-color: transparent;
}
#equipment-form .form__content hr {
  flex: 1 0 0;
  align-self: stretch;
  stroke-width: 1px;
  stroke: #b3b3b3;
}
#equipment-form .form__content .equipment-form__inputs {
  display: flex;
  gap: 32px 24px;
  flex-wrap: wrap;
}
#equipment-form .form__content .equipment-form__inputs #end-use .checkboxgroup {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  column-gap: 24px;
  row-gap: 16px;
  padding-top: 16px;
}
@media (min-width: 768px) and (max-width: 1419px) {
  #equipment-form .form__content .equipment-form__inputs #end-use .checkboxgroup {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media (max-width: 767px) {
  #equipment-form .form__content .equipment-form__inputs #end-use .checkboxgroup {
    grid-template-columns: 1fr;
  }
}
#equipment-form .form__content .equipment-form__inputs #end-use .checkboxgroup .checkbox__label {
  white-space: nowrap;
}
#equipment-form .form__content .equipment-form__inputs #electrical-power-requirement .electrical-power-requirement-title {
  display: flex;
  align-items: center;
}
#equipment-form .form__content .equipment-form__inputs #electrical-power-requirement .electrical-power-requirement-title label {
  width: 241.29px;
  flex-shrink: 0;
}
@media (max-width: 767px) {
  #equipment-form .form__content .equipment-form__inputs #electrical-power-requirement .electrical-power-requirement-title label {
    width: auto;
  }
}
#equipment-form .form__content .equipment-form__inputs #electrical-power-requirement .electrical-power-requirement-title hr {
  flex: 1 0 0;
  align-self: center;
}
@media (max-width: 767px) {
  #equipment-form .form__content .equipment-form__inputs #electrical-power-requirement .electrical-power-requirement-title hr {
    display: none;
  }
}
@media (max-width: 767px) {
  #equipment-form .form__content .equipment-form__inputs #electrical-power-requirement .content fieldset > div {
    display: grid;
    grid-template-columns: 1fr;
  }
}
#equipment-form .form__content .equipment-form__inputs .content {
  display: flex;
  gap: 24px;
  padding: 32px 0 0 0;
}
#equipment-form .form__content .equipment-form__inputs .content > * {
  flex: 1 0 0;
}
#equipment-form .form__content .equipment-form__inputs .content fieldset > div {
  display: flex;
  gap: 24px;
  padding-top: 16px;
  align-items: center;
}
#equipment-form .form__content .equipment-form__inputs .content fieldset > div label {
  white-space: nowrap;
  padding-left: 0;
}
@media (min-width: 768px) and (max-width: 1419px) {
  #equipment-form .form__content .equipment-form__inputs .content {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
@media (max-width: 767px) {
  #equipment-form .form__content .equipment-form__inputs .content {
    display: grid;
    grid-template-columns: 1fr;
  }
}
#equipment-form .form__content .equipment-form__inputs .end-uses-content {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 32px 0 0 0;
}
#equipment-form .form__content .equipment-form__inputs .end-uses-content > * {
  flex: 1 0 0;
}
#equipment-form .form__content .equipment-form__inputs .end-uses-content fieldset > div {
  display: flex;
  gap: 24px;
  padding-top: 16px;
  align-items: center;
}
#equipment-form .form__content .equipment-form__inputs .end-uses-content fieldset > div label {
  white-space: nowrap;
  padding-left: 0;
}
@media (min-width: 768px) and (max-width: 1419px) {
  #equipment-form .form__content .equipment-form__inputs .end-uses-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
}
@media (max-width: 767px) {
  #equipment-form .form__content .equipment-form__inputs .end-uses-content {
    display: grid;
    grid-template-columns: 1fr;
  }
}
#equipment-form .form__content .equipment-form__inputs .end-use-title {
  font-size: 18px;
  font-weight: 600;
  line-height: 2rem;
}
#equipment-form .form__content .equipment-form__inputs .end-use-details-button {
  display: flex;
  align-items: center;
  gap: 8px;
}
#equipment-form .form__content .equipment-form__inputs .end-use-details-button-text {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5rem;
}
#equipment-form .form__content .equipment-form__inputs .end-use-details-button:hover {
  cursor: pointer;
  color: #224bff;
}
#equipment-form .form__content .equipment-form__inputs .main-instructions {
  padding-top: 32px;
}
@media (min-width: 768px) and (max-width: 1419px) {
  #equipment-form .form__content .equipment-form__inputs .general-comments {
    grid-column: span 2;
  }
}
@media (max-width: 1419px) {
  #equipment-form .form__content .equipment-form__inputs .fill-in {
    display: none;
  }
}
#equipment-form .form__content .equipment-form__inputs #general-specifications {
  grid-area: item16;
}
#equipment-form .form__content .equipment-form__inputs textarea {
  height: 113px;
}
@media (min-width: 1420px) {
  #equipment-form .form__content .equipment-form__inputs #heating-specifications .content.part-1 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 1420px) {
  #equipment-form .form__content .equipment-form__inputs #heating-specifications .content.part-2 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
  #equipment-form .form__content .equipment-form__inputs #heating-specifications .content.part-2 #backup-control-comment {
    grid-column: span 2;
  }
}
@media (min-width: 768px) and (max-width: 1419px) {
  #equipment-form .form__content .equipment-form__inputs #heating-specifications #comments_on_heat_recovery {
    grid-column: span 2;
  }
}
@media (min-width: 1420px) {
  #equipment-form .form__content .equipment-form__inputs #cooling-unit-specification .content.part-1 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 768px) and (max-width: 1419px) {
  #equipment-form .form__content .equipment-form__inputs #pumps .variable_speed_frequency_control_comment {
    grid-column: span 2;
  }
}
#equipment-form .form__content .equipment-form__inputs #fans .content.part-1 {
  display: grid;
  grid-template-columns: 1fr;
}
@media (min-width: 768px) and (max-width: 1419px) {
  #equipment-form .form__content .equipment-form__inputs #fans .content.part-1 {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
  }
  #equipment-form .form__content .equipment-form__inputs #fans .content.part-1 > * {
    grid-column: span 5;
  }
  #equipment-form .form__content .equipment-form__inputs #fans .content.part-1 #fans-variable-speed-frequency {
    grid-column: span 4;
  }
  #equipment-form .form__content .equipment-form__inputs #fans .content.part-1 .variable_speed_frequency_control_comment {
    grid-column: span 6;
  }
}
@media (min-width: 1420px) {
  #equipment-form .form__content .equipment-form__inputs #fans .content.part-1 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 1420px) {
  #equipment-form .form__content .equipment-form__inputs #fans .variable_speed_frequency_control_comment {
    grid-column: span 3;
  }
}
#equipment-form .form__content .equipment-form__inputs .form__buttons {
  grid-area: item21;
  margin-top: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 16px;
  align-self: stretch;
}
@media (min-width: 768px) and (max-width: 1419px) {
  #equipment-form .form__content .equipment-form__inputs .form__buttons {
    justify-content: space-between;
  }
}
@media (max-width: 767px) {
  #equipment-form .form__content .equipment-form__inputs .form__buttons {
    display: grid;
    grid-template-columns: 1fr;
  }
}

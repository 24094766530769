.buildingBox .borderline {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.buildingBox .border {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 3rem;
  padding: 3em;
  width: 90%;
  border-radius: 5px;
}

.viewBtn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  width: 8.7rem;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  background-color: var(--information);
  font: 1rem var(--sisa-font-family);
  inline-size: auto;
  color: var(--jasmine);
}

.viewBtn:active,
.viewBtn:hover {
  background-color: var(--hover);
  cursor: pointer;
  color: var(--jasmine);
}

.viewBtn:focus {
  background-color: var(--information);
  border: 2px solid #20c5ff;
}

.buildingBox .border .showBtn > h2 {
  margin: 6rem;
}

.buildingBox .upperBox {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 1rem;
}

.buildingBox .upperBox .filter {
  width: 20%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.buildingBox .upperBox .filter > select {
  padding: 0.3rem;
  margin-right: 0.3rem;
  border-radius: 4px;
  width: 7rem;
  height: 2.3rem;
}

.buildingBox .upperBox .searchBar {
  width: 50%;
}

.buildingBox .upperBox .searchBar input {
  width: 100%;
  border: 2px solid var(--information);
  border-radius: 4px;
  padding: 0.3em;
  margin-right: 1rem;
}

.buildingBox .upperBox .searchBar:nth-child(2) {
  display: flex;
  align-items: center;
  justify-content: center;
}

.buildingBox .upperBox .searchBar:nth-child(2) > Button {
  height: 2.3rem;
  background-color: var(--information);
  color: var(--jasmine);
  padding: 0.3rem 1rem;
  border-radius: 4px;
}

/* .buildingBox .upperBox .view {
  width: 20%;
} */

.buildingBox .sticky-header {
  position: sticky;
  top: 0px;
  z-index: 1;
}

.buildingBox .formBtns {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

.buildingBox .iconBtn {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 0.3rem;
  width: auto;
  padding: 0.1rem;
  border: 1px solid #2359a2;
}


.searchIcon{
  background-color: none;
}

.searchBtn{
  background-color: none;
}
.report-download {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 242px;
  padding: 8px 16px;
  border-radius: 4px;
  background-color: var(--information);
  inline-size: fit-content;
  font: 600 1rem var(--sisa-font-family);
  line-height: 1;
}

.report-download-loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 220px;
  padding: 8px 16px;
  border-radius: 4px;
  background-color: transparent;
}

.report-pdf-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 240px;
  border-radius: 4px;
  background-color: transparent;
  inline-size: auto;
  gap: 0.5rem;
  color: var(--jasmine);
}

.report-download:hover,
.report-download:active {
  background-color: var(--hover);
  cursor: pointer;
}

.report-download:focus {
  background-color: var(--information);
  border: 2px solid #20c5ff;
  transition: box-shadow 0.3s ease;
  outline: none; /* Remove default focus outline */
}

.report-download p {
  width: 100%;
  font: 600 1rem var(--sisa-font-family);
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.button #loading {
  color: var(--white);
  animation: spinner 1s cubic-bezier(0.6, 0, 0.4, 1) infinite;
}

.button .loading__circle--one {
  opacity: 25%;
}
.button .loading__circle--two {
  opacity: 75%;
}

/* new css */
.forgot-container {
  margin: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 375px;
}

/* reusable logo */
.logo {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
}

.logo__img {
  width: 300px;
}

/* topBar */
.topbar-container {
  display: flex;
  gap: 1rem;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 2rem 1.5rem 1.5rem 0.5rem;
  color: var(--jasmine);
  font: 500 1rem var(--sisa-font-family);
}

.topbar-container__text {
  cursor: default;
}

.topbar-container__back-icon:hover {
  cursor: pointer;
  transition: ease, 0.5s;
}

/* successMessage */
.success-message {
  margin: 1.5rem 0.5rem 3.5rem 0.5rem;
  color: var(--jasmine);
  font: 500 1rem var(--sisa-font-family);
  text-align: center;
}

/* email */
.form-forgot__title {
  margin: 0 1.5rem 1rem 1.5rem;
  color: var(--jasmine);
  font: 500 1rem var(--sisa-font-family);
  text-align: justify;
}

.form-forgot {
  margin: 0.5rem;
  height: auto;
  width: 100%;
}

.form-forgot__label {
  font: 500 1rem var(--sisa-font-family);
  color: var(--jasmine);
  margin: 1rem 0 1rem 1rem;
}

.form-forgot__input {
  width: 90%;
  display: block;
  margin: auto;
  justify-content: center;
  height: 2.4rem;
  padding: 1.2rem;
  border-radius: 4px;
  background-color: var(--jasmine);
  margin-top: 0.5rem;
}

.form-forgot__recaptcha {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}

.form-forgot__btn {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 90%;
  min-height: 1.5rem;
  padding: 0.5rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  font: 600 1rem var(--sisa-font-family);
  color: var(--jasmine);
  background-color: var(--information);
  transition: all 0.3s ease-in-out 0s;
  cursor: pointer;
  margin: auto;
  border-radius: 4px;
}

.form-forgot__btn:active,
.form-forgot__btn:hover {
  background-color: var(--hover);
  color: var(--jasmine);
  cursor: pointer;
}

.form-forgot__btn:focus {
  background-color: var(--jasmine);
  color: var(--information);
  border: 2px solid #20C5FF;
  transition: box-shadow 0.3s ease;
  outline: none;
}

.form-forgot__btn:disabled {
  background-color: var(--ghost);
  color: var(--ash);
}

.forgot-container__frame {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: var(--indigo);
  border-radius: 4px;
  overflow: hidden;
  max-width: 432px;
  padding: 0 1rem 2rem;
  margin-left: auto;
  margin-right: auto;
}

.forgot-form__text {
  font-size: 0.6rem;
  color: var(--ghost);
  padding-left: 20px;
}

.multiauth-container {
  margin: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 375px;
}

.multiauth-container__frame {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: var(--indigo);
  border-radius: 4px;
  overflow: hidden;
  max-width: 432px;
  padding: 0 1rem 0.5rem 1rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1.25rem;
}

.top-bar-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 2rem 1rem 2rem 1.1rem;
  color: var(--jasmine);
  font: 500 1rem var(--sisa-font-family);
}

.top-bar-container__ul {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  gap: 1rem;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.top-bar-container__li-icon {
  padding-right: 0.5rem;
}

.top-bar-container__span {
  text-align: center;
  margin-top: 1rem;
}

.top-bar-container__p {
  font: 500 1rem var(--sisa-font-family);
  color: var(--turquoise);
}

.multiauth-otp-form {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
}

.multiauth-otp-form__label {
  padding-left: 0.5rem;
}

.multiauth-otp-form__input {
  width: 90%;
  display: block;
  margin: auto;
  justify-content: center;
  height: 2.4rem;
  padding: 1.2rem;
  border-radius: 4px;
  margin-bottom: 1rem;
  background-color: var(--jasmine);
  outline: none;
  color: var(--obsidian);
}

.multiauth-otp-form__btn {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: auto;
  width: 100%;
  min-width: 15rem;
  min-height: 1.5rem;
  padding: 0.5rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  font: 600 1rem var(--sisa-font-family);
  color: var(--jasmine);
  background-color: var(--information);
  transition: all 0.3s ease-in-out 0s;
  cursor: pointer;
}

.multiauth-otp-form__btn:hover,
.multiauth-otp-form__btn:hover {
  background-color: var(--hover);
  color: var(--jasmine);
  cursor: pointer;
}

.multiauth-otp-form__btn:focus {
  background-color: var(--jasmine);
  color: var(--information);
  border: 2px solid #20c5ff;
  transition: box-shadow 0.3s ease;
  outline: none;
}

.multiauth-otp-form__btn:disabled {
  background-color: var(--ghost);
  color: var(--ash);
}

.multiauth-otp-form__span--error {
  font: 500 1rem 1rem var(--sisa-font-family);
  color: var(--error);
  text-align: center;
}

.email__span {
  font: 500 1rem "Mukta", sans-serif;
  color: #00d4d0;
  text-align: center;
}

.reset__form {
  margin: 0.5rem;
  height: auto;
  width: 100%;
}

.reset-container {
  margin: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 375px;
}

.reset-container__frame {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: var(--indigo);
  border-radius: 4px;
  overflow: hidden;
  max-width: 432px;
  padding: 0 1rem 2rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1.25rem;
}

.reset__title {
  margin: 0 1.5rem 1rem 1.5rem;
  color: var(--jasmine);
  font: 500 1rem var(--sisa-font-family);
  text-align: justify;
}

.reset__p {
  margin: 2rem 1rem 1rem 1rem;
  font: 500 1rem var(--sisa-font-family);
  color: var(--jasmine);
  align-self: baseline;
  padding-left: 0.5rem;
}

.reset__label {
  font: 500 1rem var(--sisa-font-family);
  color: var(--jasmine);
  margin: 1rem 0 1rem 1rem;
}

.reset__input {
  width: 90%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  height: 2.4rem;
  padding: 1.2rem;
  border-radius: 4px;
  margin: auto;
  margin-bottom: 0.7rem;
  margin-top: 0.5rem;
  background-color: var(--jasmine);
}

.reset__msg--success {
  margin: 0.5rem 1rem 1.5rem 1rem;
  color: var(--jasmine);
  font: 500 1rem var(--sisa-font-family);
  text-align: center;
}

.reset__msg-p {
  margin: 0.5rem 1rem;
  color: var(--jasmine);
  font: 500 1rem var(--sisa-font-family);
  text-align: center;
}

.reset__msg--error {
  margin-left: 10px;
  color: var(--error);
  text-align: center;
  margin-top: 1.5rem;
}

.reset__btn {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 90%;
  min-height: 1.5rem;
  padding: 0.5rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  font: 600 1rem var(--sisa-font-family);
  color: var(--jasmine);
  background-color: var(--information);
  transition: all 0.3s ease-in-out 0s;
  cursor: pointer;
  margin: auto;
  border-radius: 4px;
  margin-top: 2rem;
  margin-bottom: 1.5rem;
}

.reset__btn:active,
.reset__btn:hover {
  background-color: var(--hover);
  color: var(--jasmine);
  cursor: pointer;
}

.reset__btn:focus {
  background-color: var(--jasmine);
  color: var(--information);
  border: 2px solid #20C5FF;
  transition: box-shadow 0.3s ease;
  outline: none;
}

.reset__btn:disabled {
  background-color: var(--ghost);
  color: var(--ash);
}

/*
Admin - Users
*/

.users-table-container,
.projects-table-container {
  width: 100%;
}

.users-table-container th,
.users-table-container td,
.projects-table-container th,
.projects-table-container td {
  font-size: 14px;
}

.users-table-container tr:nth-child(2n),
.projects-table-container tr:nth-child(2n) {
  background-color: #f5f7fa;
}

.users-table-container tr:hover,
.projects-table-container tr:hover {
  background-color: #dedede;
  cursor: pointer;
}

.users-table-container th:hover,
.projects-table-container th:hover {
  background-color: #0f3e7c;
  cursor: pointer;
}

.users-table-body-scroll {
  overflow-y: scroll;
  height: 70vh;
}

.projects-table-body-scroll {
  overflow-y: scroll;
  min-height: 10vh;
  max-height: 50vh;
}

.table-header-sticky {
  position: sticky;
  top: 0;
}

.table-header-sort {
  display: flex;
  align-items: center;
}

.button-user-projects {
  background-color: transparent;
  text-align: center;
  color: #112b4e;
}

.button-user-projects-check {
  text-align: center;
  color: #112b4e;
  padding: 4px 2px;
  cursor: pointer;
}

.delete-user-button {
  color: #112b4e;
}

.delete-user-button:disabled {
  color: #b3b3b3;
  cursor: not-allowed;
}

.delete-user-button:hover {
  color: #cf0a2e;
}

.delete-user-button:disabled:hover {
  color: #b3b3b3;
  cursor: not-allowed;
}

.button-action {
  background-color: #112b4e;
  text-align: center;
  text-transform: capitalize;
  font-size: 14px;
  width: auto;
  padding: 5px 20px;
  margin: 20px;
  color: var(--white);
}

.button-action-white {
  font-weight: bold;
  background-color: var(--white);
  text-align: center;
  text-transform: capitalize;
  font-size: 16px;
  width: auto;
  padding: 5px 20px;
  margin: 10px;
  color: #112b4e;
}

.error-no-data-text {
  text-align: center;
  padding: 2rem;
  font-size: 18px;
  color: #112b4e;
}

.user-details-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: auto;
  padding: 5px;
  margin: 5px 0;
}

.user-details-key {
  font-weight: bold;
  font-size: 14px;
  text-transform: capitalize;
  color: #112b4e;
  margin-top: 8px;
  padding: 0 10px;
}

.user-details-value {
  font-size: 14px;
  color: #112b4e;
  margin-bottom: 8px;
  padding: 0 20px;
}

.projects-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: auto;
  column-gap: 10px;
}

.table-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.index-page-container {
  display: flex;
  justify-content: space-evenly;
  margin: 50px;
  gap: 1rem;
}

.bordered-box {
  color: #112b4e;
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 200px;
  background-color: transparent;
  border: 3px solid #112b4e99;
  border-radius: 10px;
  cursor: pointer;
}

.bordered-box:hover {
  transform: scale(1.05);
  background-color: #dedede;
}

.box-menu-icon {
  font-size: 60pt;
}

.box-menu-text {
  font-size: 18pt;
  font-weight: 600;
}

@media screen and (max-width: 900px) {
  .user-details-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
    padding: 5px;
    margin: 5px 0;
  }

  .projects-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
    column-gap: 10px;
  }
}

@media screen and (min-width: 900px) and (max-width: 1525px) {
  .user-details-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-rows: auto;
    padding: 5px;
    margin: 5px 0;
  }

  .projects-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: auto;
    column-gap: 10px;
  }
}

.file-uploader-container {
  margin: 0;
}

.file-uploader-header {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  max-width: 500px;
  margin-inline: auto;
}

.upload-buttons-container {
  display: flex;
  padding: 0.5rem;
  gap: 0.5rem;
}

.file-button-action {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  min-width: 8.7rem;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  background-color: var(--information);
  font: 600 1rem var(--sisa-font-family);
  color: var(--jasmine);
}

.file-button-action:active,
.file-button-action:hover {
  background-color: var(--hover);
  cursor: pointer;
  color: var(--jasmine);
}

.file-button-action:focus {
  background-color: var(--information);
  border: 2px solid #20c5ff;
}

.file-button-action:disabled,
.file-button-action-white:disabled {
  background-color: var(--information);
  color: var(--jasmine);
  cursor: not-allowed;
}

.file-button-action-white {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  min-width: 8.7rem;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  background-color: var(--jasmine);
  color: var(--information);
  border: 1px solid var(--information);
  font: 600 1rem var(--sisa-font-family);
}

.file-button-action-white:active,
.file-button-action-white:hover {
  background-color: var(--information);
  color: var(--jasmine);
  cursor: pointer;
}

.file-button-action-white:focus {
  background-color: var(--jasmine);
  color: var(--information);
  border: 2px solid #20C5FF;
  transition: box-shadow 0.3s ease;
  outline: none;
}

.storage-indicator-container {
  padding-inline: .5rem;
  flex: 1;
  align-self: center;
  width: 100%;
}

@media (min-width: 50em) {
  .file-uploader-header {
    max-width: none;
    flex-direction: row;
    align-items: center;
  }

  .storage-indicator-container {
    max-width: 400px;
  }
}

.file-dropzone {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: dashed 2px lightskyblue;
  background-color: hsl(203, 92%, 95%);
  min-height: 10vh;
  overflow-y: auto;
  padding: 5px;
  margin: 0 0.5rem;
}

.file-input-placeholder {
  font-size: 18px;
  font-weight: 600;
  color: hsl(214, 64%, 40%);
  align-self: center;
  justify-self: center;
}

.stats-row-container {
  display: flex;
  justify-content: space-between;
}

.stats-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0.25rem;
}

.file-input-stats {
  display: flex;
  justify-content: flex-end;
}

.file-input-stats>p,
.file-uploader-restrict>p {
  font-size: 16px;
  padding: 0.25rem 0.5rem;
}

.progress-bar-container {
  width: 100%;
  padding: 1rem 0;
  min-height: 0.5rem;
}

.progress-warning-message {
  padding: 5px 0;
  font-size: 16px;
  text-align: center;
}

.bottom-container {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
}

@media (min-width: 50em) {
  .bottom-container {
    flex-direction: row;
  }
}

.action-button-container,
.status-message-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 0.5rem;
  gap: 0.5rem;
  padding: 0 0.5rem;
}

.status-message-container {
  flex: 1;
  max-width: 500px;
  margin-inline: auto;
}

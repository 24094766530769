.table {
  width: 100%;
}
.table__header {
  display: flex;
  width: 100%;
  min-height: 40px;
}
.table__header_select {
  display: flex;
  flex: 0 0 40px;
  padding: 12px;
  align-items: center;
  justify-content: space-evenly;
  background: #e6e6e6;
  border-bottom: 1px solid #b3b3b3;
  border-right: 1px solid #f9f9f9;
  cursor: pointer;
}
.table__header_select:last-child {
  border-right: none;
}
.table__header_select:hover {
  background: #b3b3b3;
}
.table__header_name {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #e6e6e6;
  border-bottom: 1px solid #b3b3b3;
  border-right: 1px solid #f9f9f9;
}
.table__header_name_text {
  padding: 12px;
  font-weight: 500;
  width: 100%;
}
.table__header_name_icon {
  cursor: pointer;
  padding: 12px;
}
.table__header_name:last-child {
  border-right: none;
}
.table__header_name:hover {
  background: #b3b3b3;
}
.table__header--disabled {
  background: #e6e6e6;
  cursor: not-allowed;
  pointer-events: none;
}
.table__header--disabled * {
  color: #737373;
}
.table__body_row {
  display: flex;
  width: 100%;
  min-height: 40px;
  background: #fff;
}
.table__body_row:hover {
  background: #e6e6e6;
}
.table__body_row--selected {
  background: #eef1ff;
}
.table__body_row_cell_select {
  display: flex;
  flex: 0 0 40px;
  padding: 12px;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #b3b3b3;
  border-right: 1px solid #f9f9f9;
}
.table__body_row_cell_select_icon {
  cursor: pointer;
}
.table__body_row_cell_select:last-child {
  border-right: none;
}
.table__body_row_cell_edit {
  display: flex;
  flex: 0 0 40px;
  padding: 12px;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #b3b3b3;
  border-right: 1px solid #f9f9f9;
}
.table__body_row_cell_edit_buttons {
  display: flex;
  flex-direction: column;
  gap: 8px;
  cursor: pointer;
}
.table__body_row_cell_edit_buttons--save {
  color: #008611;
}
.table__body_row_cell_edit_buttons--cancel {
  color: #cf0a2e;
}
.table__body_row_cell_edit:last-child {
  border-right: none;
}
.table__body_row_cell_data {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #b3b3b3;
  border-right: 1px solid #f9f9f9;
}
.table__body_row_cell_data_text {
  padding: 12px;
  width: 100%;
}
.table__body_row_cell_data:last-child {
  border-right: none;
}
.table__body_row_add_new,
.table__body_row_edit_new {
  display: flex;
  flex: 100%;
  min-height: 40px;
  padding: 12px;
  align-items: center;
  justify-content: space-between;
  color: #224bff;
  border-bottom: 1px solid #b3b3b3;
  border-right: 1px solid #f9f9f9;
  cursor: pointer;
}
.table__body_row_add_new:hover,
.table__body_row_edit_new:hover {
  background-color: #eef1ff;
}
.table__body_row--disabled {
  cursor: not-allowed;
  pointer-events: none;
}
.table__body_row--disabled * {
  color: #737373;
}
.table__no_data_box {
  display: flex;
  flex: 100%;
  min-height: 40px;
  padding: 12px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-bottom: 1px solid #b3b3b3;
  background: #fff;
}
.table__no_data_box p {
  width: 100%;
  text-align: center;
  font-size: 1rem;
  font-weight: 600;
  color: #404040;
}
.table__no_data_box--disabled {
  cursor: not-allowed;
  pointer-events: none;
}
.table__no_data_box--disabled p {
  color: #737373;
}
.cell_width--icon {
  flex: 0 0 40px;
}
.cell_width--sm {
  flex: 1 0 150px;
}
.cell_width--md {
  flex: 2 0 250px;
}
.cell_width--lg {
  flex: 3 0 350px;
}

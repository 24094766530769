.multiSelect {
  position: relative;
  display: grid;
  width: 100%;
  gap: 8px;
}
.multiSelect__label {
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  color: #141414;
  width: 100%;
}
.multiSelect__label--default {
  color: #141414;
}
.multiSelect__label--disabled {
  color: #737373;
}
.multiSelect__label--required {
  color: #cf0a2e;
}
.multiSelect__section {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  width: 100%;
}
.multiSelect__container {
  display: grid;
  grid-template-columns: 1fr 28px;
  width: 100%;
  border: 1px solid #737373;
  border-radius: 4px;
}
.multiSelect__container[data-touched="true"]:valid {
  border: 1px solid #008611;
}
.multiSelect__container[data-touched="true"]:valid:focus {
  outline: none;
  box-shadow: 0px 0px 0px 2px rgba(0, 134, 17, 0.5);
}
.multiSelect__container[data-touched="true"]:invalid {
  border: 1px solid #cf0a2e;
}
.multiSelect__container[data-touched="true"]:invalid:focus {
  outline: none;
  box-shadow: 0px 0px 0px 2px rgba(207, 10, 46, 0.5);
}
.multiSelect__container--default {
  color: #404040;
}
.multiSelect__container--default:focus {
  outline: none;
  box-shadow: 0px 0px 0px 2px #20c5ff;
  border-radius: 4px;
}
.multiSelect__container--disabled {
  border: 1px solid #b3b3b3;
  background-color: rgba(230, 230, 230, 0.5);
  color: #737373;
}
.multiSelect__container--disabled:focus {
  outline: none;
}
.multiSelect__container--readonly {
  background-color: #e6e6e6;
  color: #404040;
}
.multiSelect__container--readonly:focus {
  outline: none;
}
.multiSelect__filed {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0 16px;
  overflow-y: scroll;
  height: 50px;
  font-size: 16px;
  font-weight: 400;
  color: #404040;
}
.icon__close {
  cursor: pointer;
}
.multiSelect__placeholder {
  color: #737373;
}
.multiSelect__placeholder--disabled {
  color: #b3b3b3;
}
.multiSelect__selectedItem {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  padding: 4px 10px;
  margin: 4px;
  background-color: #224bff;
  gap: 8px;
  color: #f9f9f9;
}
.multiSelect__dropdownBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  border-left: 1px solid #737373;
}
.multiSelect__dropdownBtn--default {
  border-left: 1px solid #737373;
}
.multiSelect__dropdownBtn--disabled {
  border-left: 1px solid #b3b3b3;
  color: #b3b3b3;
}
.multiSelect__dropdownList {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #737373;
  border-radius: 4px;
  width: 100%;
  position: absolute;
  background-color: #fff;
  top: 100%;
  z-index: 999;
  left: 0;
  margin-top: 2px;
  max-height: 40vh;
  overflow-y: scroll;
  padding: 0.5rem;
}
.multiSelect__option {
  display: grid;
  grid-template-columns: 24px 1fr;
  align-items: center;
  height: 40px;
  width: 100%;
  cursor: pointer;
}
.multiSelect__optionText--selected {
  color: #224bff;
}
.multiSelect__optionText--unselected {
  color: #404040;
}
.multiSelect__optionText--unselectAll {
  color: #cf0a2e;
}

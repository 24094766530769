/* @tailwind base; */
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Mukta&display=swap");

body,
#hamburger-header,
#sub-hamburger-header,
.tabs,
p,
h2 {
  font-family: var(--sisa-font-family);
}

#tab-container {
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: space-between;
  /* width: 100%;  */
}

.tabs {
  color: var(--information);
  cursor: pointer;
  background-color: var(--ghost);
  border-left: 2px solid var(--white);
  border-right: 2px solid var(--white);
  border-top: 2px solid var(--white);
  border-radius: 0.25rem 0.25rem 0rem 0rem;
  display: inline-flex;
  padding: 0.75rem 1.25rem;
  align-items: center;
  font: 600 1rem var(--sisa-font-family);
  width: 100%;
  justify-content: center;
  letter-spacing: 0.02rem;
}

.active-tab {
  background-color: var(--jasmine);
  color: var(--indigo);

}

.disabled-tab {
  @apply cursor-default;
  color: var(--ash);
}

.content {
  @apply hidden p-5;
  background-color: var(--white);
}

.active-content {
  @apply block;
  background-color: var(--jasmine);
  border-left: 2px solid var(--white);
  border-right: 2px solid var(--white);
}

#report-last-tab.last-tab {
  padding: 0.75rem 1.4rem;
}

.contactHeader {
  font-weight: bold;
  color: var(--black);
  display: inline-block;
  font-size: 16px;
  margin: 0.5rem 1rem;
}

.contactList {
  margin: 0 1rem;
}

.contactList li {
  color: var(--black);
  text-transform: none;
  display: list-item;
  list-style-position: inside;
  list-style-type: disc;
  font-size: 16px;
}

.detaiList {
  margin-left: 0.5rem;
  /* margin-top: 1rem; */
}

.detaiList li {
  color: var(--black);
  text-transform: none;
  display: list-item;
  list-style-position: inside;
  list-style-type: disc;
  font-size: 16px;
  margin: 0.3rem 0;
}

.mobile-version-btn {
  display: none;
}

#hamburger-header,
#sub-hamburger-header {
  display: none;
}

.report-header-mobile {
  visibility: hidden;
}

.report_equipment_delete_button {
  font-size: 20px;
}

.report_equipment_delete_button:hover {
  cursor: pointer;
}

@media (min-width: 1440px) {
  .widget-header {
    flex-wrap: wrap;
  }

  #tab-container.report-tabs {
    display: none;
  }
}

@media (max-width: 1501px) and (min-width: 768px) {
  #tab-container {
    display: flex;
    flex-wrap: wrap;
  }

  /* Adjust tab width to make 2 rows with 4 tabs in each row */
  .tabs {
    width: calc(25% - 0px);
    order: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .active-tab {
    width: 100%;
    order: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    font: 600 1rem var(--sisa-font-family);
  }

  .disabled-tab {
    color: var(--ash);
  }

  #tab-container.report-tabs {
    display: none;
  }

  .report-pdf-btn>p.download-report-pdf {
    line-height: 1;
  }
}

@media (max-width: 768px) {
  #tab-content-container {
    display: flex;
    flex-direction: column;
    height: 768px;
    padding: 0;
    gap: 0;
  }

  #tab-content-container>div {
    width: 100%;
    height: 100%;
    margin-bottom: 3rem;
  }

  #tab-contents-partifipant {
    height: auto;
    margin-bottom: 20px;
  }

  #diagrams-piChart,
  #diagrams-barChart {
    margin-top: 50px;
    overflow: auto;
    font-family: var(--sisa-font-family);
  }

  #diagram-container-1,
  #diagram-container-2 {
    display: block;
  }

  #diagram-container-1>div:first-child,
  #diagram-container-2>div:first-child {
    margin-bottom: 10px;
  }

  ul#tab-container {
    /* how to make width full */
    width: 100%;
  }

  .pdf-down-btn>.report-download {
    display: none;
  }

  .pdf-down-btn>.button.button:last-child {
    display: block;
    width: 150px;
  }

  .mobile-version-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 2.5rem;
    /* width: 8.7rem; */
    padding: 0.5rem 1rem;
    border-radius: 4px;
    background-color: var(--information);
    font: 1rem var(--sisa-font-family);
    color: var(--jasmine);
    gap: 0.3rem;
  }

  .mobile-version-btn:hover {
    background-color: var(--hover);
    cursor: pointer;
    color: var(--jasmine);
  }

  .mobile-version-btn:focus {
    background-color: var(--information);
    border: 2px solid #20c5ff;
  }

  #hamburger-header,
  #sub-hamburger-header {
    flex-grow: 1;
    text-align: center;
    font-size: 1.125rem;
    line-height: 1.75rem;
    @apply px-2 py-2;
  }

  .active-tab {
    display: none;
  }

  .report-header-mobile {
    visibility: visible;
    text-align: center;
  }

  /* #report-container, */
  #utility-rates,
  #electric-consumption-section,
  #gas-consumption-section,
  #total-consumption-section {
    scroll-margin-top: 60px;
  }

  #report-container {
    scroll-margin-top: 60px;
  }

  #report-last-tab.last-tab {
    padding: 0.75rem 1.4rem 0.75rem 1.25rem;
  }
}

.report-tabs {
  flex-wrap: wrap;
}

.chart-border {
  border: 1px solid var(--ghost);
  border-top: none;
}

.heading {
  display: flex;
  justify-content: left;
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  padding: 1rem;
  color: var(--indigo);
  text-align: left;
}

.container {
  border: 1px solid var(--ghost);
  padding: 1rem 1.5rem;
  font-size: 16px;
  color: var(--obsidian);
  margin-bottom: 2rem;
}

.sofiacLogo {
  width: 150px;
  height: 46px;
  margin-bottom: 1.5rem;
}

.cmhcLogo {
  width: 150px;
  height: 71px;
  margin-bottom: 1.5rem;
}

.title {
  font-size: 18px;
  font-weight: 700;
  text-decoration: underline;
  text-decoration-thickness: 0.5px;
  text-underline-offset: 2px;
}

.orgIntro {
  margin: 1.5rem 0;
}

.approachHead {
  font-size: 18px;
  font-weight: 700;
  margin: 0.5rem 0;
}

.DetailPoints {
  margin-bottom: 1.5rem;
}

.DetailPoints li {
  font-size: 16px;
  color: var(--black);
  text-transform: none;
  display: list-item;
  list-style-position: inside;
  list-style-type: disc;
}

.eligibilityHeader {
  font-size: 18px;
  font-weight: 700;
  margin: 1.5rem 0 0.5rem 0;
}

.eligibilityDetailSection {
  margin: 2rem 0;
  font-size: 18px;
  font-weight: 700;
}

.eligibilityDetail {
  display: grid;
  grid-template-columns: 2fr 5fr;
  margin: 12px 0 12px 1.7rem;
  gap: 1rem;
}

.result {
  margin: 1.5rem 0;
}

.annotates {
  font-size: 12px;
  margin-top: 1rem;
}

.annotates div {
  margin-bottom: 0.5rem;
}

.annotateLink {
  text-decoration: underline;
  text-decoration-thickness: 0.5px;
  text-underline-offset: 2px;
}

.title:hover,
.annotateLink:hover {
  color: var(--information);
}

.eligibaleGreen {
  color: var(--success);
}

.notEligibleRed {
  color: var(--error);
}

.noData {
  color: var(--ash)
}

.incentiveResult {
  font-size: 18px;
  font-weight: 700;
}

@media (max-width: 768px) {
  .eligibilityDetail {
    grid-template-columns: 3fr 5fr;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .eligibilityDetail {
    grid-template-columns: 1fr 3fr;
  }
}

body{
  font-family: var(--sisa-font-family);
}

#alternateOption {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid rgb(134, 134, 134);
  line-height: 0.1em;
  margin: 55px 0 55px;
  color: rgb(133, 133, 133);
}

#alternateOption span {
  background: var(--white);
  padding: 0 10px;
}

.porfolio-planner-container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.budet-container {
  display: flex;
  position: relative;
}

.year-slider {
  display: grid;
  grid-template-columns: 1fr 60px;
  border: 1px solid var(--mist);
  border-radius: 4px;
  height: 40px;
  width: 100%;
  justify-content: center;
  align-items: center;
  background-color: var(--ghost);
  /* margin: 8px 0px; */
  padding: 0px 10px;
  gap: 16px;
}

.budget-filter-buttons-container {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 16px;
}

/* #apply-filters-button,
#reset-filters-button {
  background-color: #03045e;
  width: 48%;
  height: 40px;
} */



#reset-filters-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  width: 100%;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  background-color: var(--jasmine);
  color: var(--information);
  border: none;
  font: 500 16px var(--sisa-font-family);
  border: 1px solid var(--information);
  box-shadow: none;
}

#reset-filters-button:active,
#reset-filters-button:hover {
  background-color: var(--information);
  cursor: pointer;
  color: var(--jasmine);
}

#reset-filters-button:focus {
  background-color: var(--jasmine);
  color: var(--information);
  border: 2px solid #20C5FF;
  transition: box-shadow 0.3s ease;
  outline: none;
  /* Remove default focus outline */
}

/* new style for the budget apply change btn */
#apply-filters-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  width: 100%;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  background-color: var(--information);
  color: var(--jasmine);
  border: none;
  font: 500 16px var(--sisa-font-family);
  box-shadow: none;
}

#apply-filters-button:active,
#apply-filters-button:hover {
  background-color: var(--hover);
  cursor: pointer;
}

#apply-filters-button:focus {
  background-color: var(--information);
  border: 2px solid #20c5ff;
  transition: box-shadow 0.3s ease;
  outline: none;
  /* Remove default focus outline */
}

#apply-filters-button:disabled,
#reset-filters-button:disabled {
  background-color: var(--ghost);
  color: var(--ash);
  font-weight: bold;
  border: none;
}

@media (min-width: 768px) {
  .switch-button {
    display: flex;
    position: absolute;
    right: -36px;
    top: -68px;
    justify-content: center;
    align-items: center;
    gap: 1em;
    padding: 0 1rem;
  }

  .budget-filter-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: flex-end;
    gap: 10px;
    padding-bottom: 1rem;
  }

  .variable-annual-budget-on {
    display: grid;
    grid-template-columns: 2fr 1fr;
    justify-content: center;
    align-items: flex-end;
    gap: 16px;
  }
}

@media (max-width: 768px) {
  .budget-filter-container {
    display: grid;
    grid-template-rows: 80px 80px 120px;
    gap: 10px;
    margin-top: 3rem;
  }

  .variable-annual-budget-on {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
    margin-top: 3rem;
  }

  .budget-filter-buttons-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    gap: 16px;
    height: 120px;
  }

  .switch-button {
    display: flex;
    position: absolute;
    right: 0;
    top: 0;
    justify-content: center;
    align-items: center;
    gap: 1em;

  }

  #budget-for-year {
    display: grid;
    grid-template-columns: 1fr;
    margin-top: 1rem;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  #budget-for-year {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 16px;
    margin-top: 1rem;
  }
}

@media (min-width: 1024px) {
  #budget-for-year {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 16px;
    margin-top: 1rem;
  }
}

.buildingBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: var(--white);
}

.btnContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 3rem;
  margin: 1rem 0;
  width: 100%;
}

.buildingBox .editOrAddBtnActive {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  width: 8.7rem;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  background-color: var(--information);
  font: 1rem var(--sisa-font-family);
  color: var(--jasmine);
}

.buildingBox .editOrAddBtnActive:active,
.buildingBox .editOrAddBtnActive:hover {
  background-color: var(--hover);
  cursor: pointer;
  color: var(--jasmine);
}

.buildingBox .editOrAddBtnActive:focus {
  background-color: var(--information);
  border: 2px solid #20c5ff;
}

.buildingBox .editOrAddBtnInactive {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  width: 8.7rem;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  background-color: var(--jasmine);
  color: var(--information);
  border: 1px solid var(--information);
  font: 500 1rem var(--sisa-font-family);
}

.buildingBox .editOrAddBtnInactive:active,
.buildingBox .editOrAddBtnInactive:hover {
  background-color: var(--information);
  color: var(--jasmine);
  cursor: pointer;
}

.buildingBox .editOrAddBtnInactive:focus {
  background-color: var(--jasmine);
  color: var(--information);
  border: 2px solid #20C5FF;
  transition: box-shadow 0.3s ease;
  outline: none;
  /* Remove default focus outline */
}
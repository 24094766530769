#lighting-landing-page .form__buttons__right {
  grid-template-columns: auto;
  margin-top: 0;
}
#lighting-landing-page .page_no_data_overlay {
  display: flex;
  height: 40px;
  padding: 12px 8px;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border: 1px solid #b3b3b3;
  background: #fff;
}
#lighting-landing-page .page_no_data_overlay p {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
  font-size: 12px;
}
#lighting-landing-page .material-symbols-outlined {
  font-size: 1.04463rem;
}
#lighting-landing-page .draft-edit-button {
  cursor: pointer;
  color: #224bff;
  font-family: Work Sans;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem;
}
#lighting-landing-page .draft-edit-button:hover {
  color: hsl(226, 74%, 68%);
}
#lighting-landing-page .draft-delete-button {
  cursor: pointer;
  color: #cf0a2e;
  font-family: Work Sans;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1rem;
}
#lighting-landing-page .draft-delete-button:hover {
  color: hsl(0, 74%, 68%);
  background-color: transparent;
}

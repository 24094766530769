.input {
  display: grid;
  position: relative;
  width: 100%;
  gap: 8px;
}
.input__label {
  font-size: 16px;
  font-weight: 500;
  line-height: 22px;
  color: #141414;
}
.input__label--default {
  color: #141414;
}
.input__label--disabled {
  color: #737373;
}
.input__label--required {
  font-weight: 700;
  color: #cf0a2e;
}
.input__container {
  display: flex;
  align-items: center;
}
.input__icon {
  margin-left: 10px;
}
.input__icon--left {
  left: 16px;
}
.input__icon--right {
  right: 16px;
}
.input__field {
  border: 1px solid #737373;
  border-radius: 4px;
  height: 40px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding: 10px 16px;
  width: 100%;
  background-color: #fff;
}
.input__field:disabled {
  opacity: 0.7;
}
.input__field::placeholder {
  color: #737373;
}
.input__field--default {
  color: #404040;
}
.input__field--default:focus {
  outline: none;
  box-shadow: 0px 0px 0px 2px #20c5ff;
}
.input__field--valid {
  border: 2px solid #008611;
}
.input__field--valid:focus {
  outline: none;
  box-shadow: 0px 0px 0px 2px rgba(0, 134, 17, 0.5);
}
.input__field--invalid {
  border: 2px solid #f53054;
}
.input__field--invalid:focus {
  outline: none;
  box-shadow: 0px 0px 0px 2px rgba(207, 10, 46, 0.5);
}
.input__field--icon--left {
  padding-left: 40px;
}
.input__field--icon--right {
  padding-right: 40px;
}
.input__field--disabled {
  border: 1px solid #b3b3b3;
  background-color: rgba(230, 230, 230, 0.5);
  color: #b3b3b3;
}
.input__field--disabled:focus {
  outline: none;
}
.input__field--disabled::placeholder {
  color: #b3b3b3;
}
.input__field--readonly {
  background-color: #e6e6e6;
  color: #404040;
}
.input__field--readonly:focus {
  outline: none;
}
.input__validation {
  font-size: 12px;
}
.input__validation--invalid {
  color: #cf0a2e;
}
.input__validation--valid {
  color: #008611;
}

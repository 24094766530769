/* Tailwind CSS file should be imported to App.js/Index.js. Import order matters as it will override other styles depending on order.
Currently, it should be imported near top. */
@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:wght@200;300;400;500;600;700;800&display=swap");

:root {
  /* -- System Fonts -- */
  --sisa-font-family: "Work Sans", sans-serif;

  /* -- System Colors -- */

  /* Neutral Colors */
  --obsidian: #141414;
  --charcoal: #404040;
  --ash: #737373;
  --mist: #b3b3b3;
  --ghost: #e6e6e6;
  --jasmine: #f9f9f9;

  --black: #000;
  --white: #fff;

  /* Semantic Colors */
  --alert: #eb6b14;
  --error: #cf0a2e;
  --success: #008611;
  --hover: #2e45be;
  --information: #224bff;

  /* SISA Energy Brand Color Palette */
  --berry: #0073e6;
  --cobalt: #2546f0;
  --emerald: #1ba728;
  --indigo: #011148;
  --parakeet: #30d449;
  --turquoise: #00d4d0;
}

*,
a,
body,
html,
h1,
tr,
th,
td,
p {
  font-family: var(--sisa-font-family);
}

/* Custom standardized Tailwind classes instead of hardcoding every Tailwind class */
@layer components {
  .page-title {
    @apply text-4xl text-black font-mukta;
  }

  .charts-graphs-tables-container {
    @apply flex flex-col gap-10 h-full;
  }

  .table-widget {
    @apply flex flex-col rounded drop-shadow-md p-5 h-full;
    background-color: var(--white);
  }
}

/* Change the white to any color */
/* For WebKit-based browsers (Chrome, Safari) */
input:-webkit-autofill,
input:-moz-autofill {
  -webkit-text-fill-color: #141414 !important;
  background-color: var(--jasmine) !important;
}

::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 100px;
  background: rgb(194, 194, 194);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  cursor: pointer;
  background: rgb(177, 177, 177);
}

.main-page {
  display: grid;
  grid-template-columns: 256px 1fr;
  min-width: 320px;
  width: 100%;
}

.title {
  height: 2rem;
  display: flex;
  align-items: center;
}

.hr_breaker {
  background-color: var(--indigo);
  height: 2px;
  border: none;
}

.hr_container {
  margin: 1rem 0;
}

.section_heading_1 {
  color: var(--indigo);
  font: 600 28px/36px var(--sisa-font-family);
}

.section_heading_2 {
  color: var(--indigo);
  font: 600 20px/28px var(--sisa-font-family);
}

.section_heading_3 {
  color: var(--indigo);
  font: 600 18px/24px var(--sisa-font-family);
}

.table_heading {
  color: var(--indigo);
  font: 500 16px/24px var(--sisa-font-family);
}

.custom-frame {
  border: 1px solid var(--ghost);
}

#sub-page-owner,
#sub-page-viewer,
#sub-page-admin,
#sub-page-basic {
  background-color: var(--white);
  margin-top: 0px;
  padding: 32px;
  height: 100%;
  width: 100%;
  overflow: auto;
}

@media (max-width: 1023px) {
  .main-page {
    display: flex;
    flex-direction: column;
  }

  #sub-page-owner,
  #sub-page-viewer,
  #sub-page-admin,
  #sub-page-basic {
    width: 100%;
    margin-top: 0px;
  }
}

@media (max-width: 429px) {
  #sub-page-owner,
  #sub-page-viewer,
  #sub-page-admin,
  #sub-page-basic {
    padding: 32px 16px;
    z-index: 0;
    width: 100%;
    margin-top: 0px;
  }

  .section_heading_1 {
    color: var(--jasmine);
  }
}

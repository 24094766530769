.select-year {
  background-color: transparent;
  height: 24pt;
  border: solid 1px lightgray;
  border-radius: 5px;
}

.select-year:hover {
  cursor: pointer;
}

.end-year-input-label {
  color: var(--indigo);
  font-weight: 600;
}

.end-year-input {
  border: solid 1px lightgray;
  border-radius: 5px;
  padding: 5px 10px;
  color: var(--indigo);
  font-weight: 600;
  width: 70px;
  text-align: center;
}

.plan-name-input {
  width: 20vw;
  border: solid 1px lightgray;
  height: 40px;
  padding: 10px;
  border-radius: 5px;
}

.end-year-inc-icon,
.end-year-dec-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px lightgray;
  border-radius: 5px;
  width: 36px;
  height: 36px;
  cursor: pointer;
}

.end-year-inc-icon:hover,
.end-year-dec-icon:hover {
  background-color: hsl(203, 92%, 85%);
}

.end-year-inc-icon:active,
.end-year-dec-icon:active {
  background-color: hsl(203, 92%, 70%);
  transform: translateY(2px);
}

.save_plan_name_form {
  display: flex;
  align-items: center;
}

@media (max-width: 768px) {
  .decarb-end-year-container {
    display: grid;
    grid-template-rows: 1fr 1fr;
  }
}

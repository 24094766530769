.filter-form {
  display: grid;
  align-items: flex-end;
}

.filter-label {
  font-weight: 600;
  font-size: 12pt;
  padding: 10px;
  color: var(--indigo);
  display: block;
}

.filter-select {
  font-size: 12pt;
}

.filter-input {
  width: 100%;
  font-size: 12pt;
  padding: 0.5em;
  margin: 0;
  border: solid 1px rgb(205, 205, 205);
  border-radius: 5px;
}

.select-options {
  font-size: 12pt;
}

.select-decarb-plan {
  font-size: 16px;
  margin-right: 6px;

}

#f-roi-text {
  display: flex;
  justify-self: flex-end;
  font-size: 12pt;
  font-weight: 600;
  color: var(--indigo);
  width: 2.5em;
}

#f-roi-input {
  display: grid;
  grid-template-columns: 7fr 1fr;
  align-items: center;
  gap: 12px;
  padding-left: 6px;
}

.select-options,
.filter-input,
.filter-slider {
  height: 40px;
}

#filter-buttons {
  display: grid;
  gap: 1rem;
  margin-top: 2rem;
}

#bundel-buttons {
  display: grid;
  gap: 1rem;
}

/* button: */
.submit-button-blue {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  width: 100%;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  background-color: var(--information);
  color: var(--jasmine);
  border: none;
  font: 600 16px var(--sisa-font-family);
}

.submit-button-blue:active,
.submit-button-blue:hover {
  background-color: var(--hover);
  cursor: pointer;
}

/* focus */
.submit-button-blue:focus {
  background-color: var(--information);
  border: 2px solid #20c5ff;
  transition: box-shadow 0.3s ease;
  outline: none;
  /* Remove default focus outline */
}

.reset-button-gray {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  width: 100%;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  background-color: var(--jasmine);
  color: var(--information);
  border: none;
  font: 600 16px var(--sisa-font-family);
  border: 1px solid var(--information);
}

.reset-button-gray:active,
.reset-button-gray:hover {
  background-color: var(--information);
  cursor: pointer;
  color: var(--jasmine);
}

/* focus */
.reset-button-gray:focus {
  background-color: var(--jasmine);
  border: 2px solid #20c5ff;
  color: var(--information);
  transition: box-shadow 0.3s ease;
  outline: none;
  /* Remove default focus outline */
}

/* disabled */
.reset-button-gray:disabled,
.submit-button-blue:disabled {
  background-color: var(--ghost);
  color: var(--ash);
  font-weight: 600;
  border: none;
}

@media (min-width: 768px) {
  .first-row-inputs {
    display: grid;
    grid-template-columns: 1fr 1fr 2fr;
    align-items: flex-end;
  }

  .second-row-inputs {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-items: flex-end;
  }

  .third-row-inputs {
    display: grid;
    grid-template-columns: 1fr 1fr 2fr;
    align-items: flex-end;
  }

  .bundle-filter-first-row {
    display: grid;
    grid-template-columns: 1fr 2fr;
    align-items: flex-end;
  }

  .bundle-filter-second-row {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    align-items: flex-end;
  }

  .bundle-filter-third-row {
    display: grid;
    grid-template-columns: 1fr 1fr 2fr;
    align-items: flex-end;
  }

  #filter-buttons,
  #bundel-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-self: flex-end;
    height: fit-content;
    gap: 10px;
  }

  #bundel-buttons {
    margin-top: 0;
  }

  .filter-parameter,
  #filter-buttons {
    padding: 0 10px 10px 0px;
  }

  .reset-button-gray,
  .submit-button-blue {
    width: 10rem;
  }
}

@media (max-width: 400px) {
  .submit-button-blue {
    line-height: 1;
  }
}

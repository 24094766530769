.add-button,
.equip-modal-cancel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  width: 8.8rem;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  background-color: var(--jasmine);
  color: var(--information);
  margin-right: 0.28rem;
  border: 1px solid var(--information);
  font: 600 16px var(--sisa-font-family);
  line-height: 1;
}

.equip-modal-cancel {
  border: 1px solid var(--information);
}

.add-button:active,
.add-button:hover,
.equip-modal-cancel:active,
.equip-modal-cancel:hover {
  background-color: var(--information);
  color: var(--jasmine);
  cursor: pointer;
}

.add-button:focus,
.equip-modal-cancel:focus {
  background-color: var(--jasmine);
  color: var(--information);
  border: 2px solid #20C5FF;
  transition: box-shadow 0.3s ease;
  outline: none;
}

.add-equip-btn:focus {
  width: auto;
}

.equip-modal-ok {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  width: 8.7rem;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  background-color: var(--information);
  color: var(--jasmine);
}

.equip-modal-ok:active,
.equip-modal-ok:hover {
  background-color: var(--hover);
  cursor: pointer;
  color: var(--jasmine);
}

.equip-modal-ok:focus {
  background-color: var(--information);
  border: 2px solid #20c5ff;
  transition: box-shadow 0.3s ease;
  outline: none;
  /* Remove default focus outline */
}

.add-button:disabled,
.equip-modal-ok:disabled {
  background-color: var(--ghost);
  color: var(--ash);
}

.modal-delete-eq {
  margin: auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  width: 450px;
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 2px 3px 5px #999;
}

.modal-add-eq {
  position: relative;
  width: 650px;
  margin: auto;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(255, 255, 255, 1);
  box-shadow: 2px 3px 5px #999;
}

.popup-container {
  padding: 20px 20px 20px 20px;
  flex: 1;
  text-align: center;
}

.popup-body {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.input-container {
  text-align: center;
}

.button-container {
  display: flex;
  justify-content: flex-end;
}

.delete-button {
  background-color: transparent;
  border: 1px solid var(--error);
  color: var(--error);
  width: 40px;
}

.delete-button:hover {
  background-color: var(--error);
  color: var(--white);
}

.modal-delete {
  color: var(--white);
  background-color: var(--error);
  border-radius: 4px;
  cursor: pointer;
}

.modal-footer {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.modal-message {
  text-align: center;
}

.modal-close {
  background-color: blue;
  border: none;
  cursor: pointer;
}

.modal-header {
  display: flex;
  justify-content: flex-end;
}

.ReactModal__Overlay {
  z-index: 10;
}

/* modal in the addEquip */
.popup {
  width: 50%;
  height: 50%;
}

.popup__container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  font: 500 1rem var(--sisa-font-family);
  gap: 1rem;
}

.popup__label {
  font: 1rem var(--sisa-font-family);
  line-height: 0;
  margin: 1.5rem 0;
  color: var(--indigo);
}

.popup__input {
  font: 1rem var(--sisa-font-family);
  line-height: 1;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  border: 1px solid var(--mist);
  width: 100%;
}

@media (max-width: 430px) {
  .add-button {
    font-size: 0.8rem;
    inline-size: min-content;
    line-height: 1;
  }
}

.button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  white-space: nowrap;
  cursor: pointer;
}
@media (max-width: 320px) {
  .text-display {
    display: none;
  }
}
.icon--with--text {
  padding: 4px 5px;
  font-size: 18px;
  font-weight: 300;
  line-height: normal;
}
@media (max-width: 768px) and (min-width: 320px) {
  .icon--sidebar {
    padding: 3px 4px;
    font-size: 20px;
  }
}
@media (max-width: 320px) {
  .icon--sidebar {
    padding: 2px 3px;
    font-size: 22px;
  }
}
.button__large {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  min-width: 150px;
  height: 40px;
  padding: 8px 16px;

}
.button__medium {
  font-size: 12px;
  font-weight: 600;
  line-height: normal;
  min-width: 120px;
  height: 34px;
  padding: 10px 12px;
}
.button__small {
  font-size: 10px;
  font-weight: 500;
  line-height: normal;
  min-width: 100px;
  height: 28px;
  padding: 8px 10px;
}
.button__large--primary,
.button__medium--primary,
.button__small--primary {
  background-color: #224bff;
  color: #f9f9f9;
}
.button__large--primary:active,
.button__medium--primary:active,
.button__small--primary:active,
.button__large--primary:hover,
.button__medium--primary:hover,
.button__small--primary:hover {
  background-color: #2e45be;
}
.button__large--primary:focus,
.button__medium--primary:focus,
.button__small--primary:focus {
  box-shadow: 0px 0px 0px 2px #20c5ff;
}
.button__large--primary:disabled,
.button__medium--primary:disabled,
.button__small--primary:disabled {
  background-color: #e6e6e6;
  color: #737373;
}
.button__large--secondary,
.button__medium--secondary,
.button__small--secondary {
  background-color: #f9f9f9;
  color: #224bff;
  border: 1px solid #224bff;
}
.button__large--secondary:active,
.button__medium--secondary:active,
.button__small--secondary:active,
.button__large--secondary:hover,
.button__medium--secondary:hover,
.button__small--secondary:hover {
  background-color: #224bff;
  color: #f9f9f9;
}
.button__large--secondary:focus,
.button__medium--secondary:focus,
.button__small--secondary:focus {
  box-shadow: 0px 0px 0px 2px #20c5ff;
}
.button__large--secondary:disabled,
.button__medium--secondary:disabled,
.button__small--secondary:disabled {
  background-color: #e6e6e6;
  color: #737373;
  border: 1px solid #737373;
}
.button__large--gray,
.button__medium--gray,
.button__small--gray {
  background-color: #737373;
  color: #f9f9f9;
}
.button__large--gray:active,
.button__medium--gray:active,
.button__small--gray:active,
.button__large--gray:hover,
.button__medium--gray:hover,
.button__small--gray:hover {
  background-color: #404040;
}
.button__large--gray:focus,
.button__medium--gray:focus,
.button__small--gray:focus {
  box-shadow: 0px 0px 0px 2px #20c5ff;
}
.button__large--gray:disabled,
.button__medium--gray:disabled,
.button__small--gray:disabled {
  background-color: #e6e6e6;
  color: #737373;
}
.button__large--secondary--gray,
.button__medium--secondary--gray,
.button__small--secondary--gray {
  background-color: #f9f9f9;
  color: #737373;
  border: 1px solid #737373;
}
.button__large--secondary--gray:active,
.button__medium--secondary--gray:active,
.button__small--secondary--gray:active,
.button__large--secondary--gray:hover,
.button__medium--secondary--gray:hover,
.button__small--secondary--gray:hover {
  background-color: #737373;
  color: #f9f9f9;
}
.button__large--secondary--gray:focus,
.button__medium--secondary--gray:focus,
.button__small--secondary--gray:focus {
  box-shadow: 0px 0px 0px 2px #20c5ff;
}
.button__large--secondary--gray:disabled,
.button__medium--secondary--gray:disabled,
.button__small--secondary--gray:disabled {
  background-color: #e6e6e6;
}
.button__with__icon {
  padding: 8px 16px 8px 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  height: 42px;
  gap: 8px;
}
.button__with__icon--primary {
  background-color: #224bff;
  color: #f9f9f9;
}
.button__with__icon--primary:active,
.button__with__icon--primary:hover {
  background-color: #2e45be;
}
.button__with__icon--primary:focus {
  box-shadow: 0px 0px 0px 2px #20c5ff;
}
.button__with__icon--primary:disabled {
  background-color: #e6e6e6;
  color: #737373;
}
.button__with__icon--secondary {
  background-color: #f9f9f9;
  color: #224bff;
  border: 1px solid #224bff;
}
.button__with__icon--secondary:active,
.button__with__icon--secondary:hover {
  background-color: #224bff;
  color: #f9f9f9;
}
.button__with__icon--secondary:focus {
  box-shadow: 0px 0px 0px 2px #20c5ff;
}
.button__with__icon--secondary:disabled {
  background-color: #f9f9f9;
  color: #737373;
  border: 1px solid #737373;
}
.button__banner {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  border-radius: 3px;
  padding: 0px 8px;
  height: 24px;
  flex-shrink: 0;
  color: #141414;
  background-color: rgba(249, 249, 249, 0.5);
}
.button__banner:focus {
  box-shadow: 0px 0px 0px 2px #20c5ff;
}
.button__banner--link {
  text-decoration: underline;
}
.button__banner--link:focus {
  text-decoration: none;
}
.button__sidebar {
  color: #011148;
  font-size: 14px;
  font-weight: 500;
  padding: 12px 16px;
  line-height: 20px;
  max-width: 224px;
  gap: 12px;
}
.button__sidebar:active {
  color: #224bff;
}
.button__sidebar:focus {
  color: #224bff;
  border: 2px solid #224bff;
}
.button__sidebar:hover {
  background-color: #e6e6e6;
  color: #224bff;
}
.button__sidebar:disabled {
  color: #737373;
  background-color: transparent;
}
@media (max-width: 768px) and (min-width: 320px) {
  .button__sidebar {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 8px;
    line-height: 16px;
    padding: 8px;
    min-width: 70px;
    gap: 4px;
  }
}
@media (max-width: 320px) {
  .button__sidebar {
    padding: 8px;
  }
}

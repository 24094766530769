.accordion_container {
    border: solid 1px lightgray;
}

.accordion_summary_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    cursor: pointer;
}

.accordion_summary_text {
    align-items: center;
    display: flex;
    column-gap: 10px;
    font-weight: 600;
}

.accordion_summary_text>span {
    font-size: 30px;
}

html,
body * {
  box-sizing: border-box;
  font-family: var(--sisa-font-family);
}

body::-webkit-scrollbar {
  display: none;
}

body {
  background: var(--jasmine);
  /* url(https://dl.dropboxusercontent.com/u/22006283/preview/codepen/sky-clouds-cloudy-mountain.jpg)
            no-repeat center center fixed; */
  background-size: cover;
}

/* Add a right margin to each icon */
.fa {
  margin-left: -12px;
  margin-right: 8px;
}

.main-sign-in {
  margin: auto;
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.main-login__container {
  display: flex;
  margin: 2rem .5rem 1rem .5rem;
  flex-wrap: wrap;
}

.password-rules-text {
  padding: 1rem .5rem;
  color: var(--jasmine);
  font-size: 12px;
}

/* big container */
.phone-login__container {
  margin: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 375px;
}

.main-login__frame,
.phone-login__frame {
  width: 25rem;
  padding: 0 1rem 3rem 1rem;
  background: var(--indigo);
  margin: auto auto 6rem auto;
  border-radius: 5px;
  transition: all 0.5s ease;
}

/* icon + text = heading */
.phone-login__number-anchor-icon {
  display: flex;
  cursor: pointer;
  transition: all 0.3s ease-in-out 0s;
  color: var(--jasmine);
  font: 500 1rem var(--sisa-font-family);
  margin: 2rem 1.5rem 1.5rem 0.5rem;
  gap: 1rem;
}

.main-login__forgot-text {
  margin: 0.5rem 1rem;
  color: var(--jasmine);
  font: 500 14px var(--sisa-font-family);
  text-align: center;
  display: inherit;
}

/* form */
.main-login-form-small-text {
  font-size: 0.6rem;
  color: var(--ghost);
  padding-left: 20px;
}

.main-login-form__msg--error {
  color: var(--error);
  text-align: center;
  padding-bottom: 2rem;
}

/* resend otp */
.phone-login__otp {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.phone-login__desc {
  width: 100%;
  margin: 0 1rem 1.5rem 1rem;
  color: var(--jasmine);
  font: 500 1rem var(--sisa-font-family);
}

.phone-login__label,
.main-login__label {
  font: 500 1rem var(--sisa-font-family);
  text-transform: uppercase;
  padding-left: 1rem;
  padding-bottom: 0.5rem;
  color: var(--jasmine);
  display: block;
}

.phone-login__input,
.main-login__input {
  width: 95%;
  display: block;
  margin: auto;
  justify-content: center;
  height: 2.4rem;
  padding: 1.2rem;
  border-radius: 4px;
  margin-bottom: 1rem;
  background-color: var(--jasmine);
  outline: none;
}

/* button styling */
.phone-login__otp-btn {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: auto;
  width: 90%;
  min-width: 15rem;
  min-height: 1.5rem;
  padding: 0.5rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  font: 600 1rem var(--sisa-font-family);
  color: var(--jasmine);
  background-color: var(--information);
  transition: all 0.3s ease-in-out 0s;
  cursor: pointer;
  margin-top: 2rem;
  border-radius: 4px;
}

.phone-login__submit-btn,
.main-login__submit-btn {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: auto;
  width: 90%;
  min-width: 15rem;
  min-height: 1.5rem;
  padding: 0.5rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  font: 600 1rem var(--sisa-font-family);
  color: var(--jasmine);
  background-color: var(--information);
  transition: all 0.3s ease-in-out 0s;
  cursor: pointer;
  margin-top: 0.5rem;
  border-radius: 4px;
}

.phone-login__submit-btn {
  margin-bottom: 1.3rem;
}

.main-login__submit-btn {
  width: 90%;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  justify-content: center;
  margin-top: 2rem;
}

.main-login__submit-btn:acitve,
.main-login__submit-btn:hover,
.phone-login__otp-btn:active,
.phone-login__submit-btn:active,
.phone-login__otp-btn:hover,
.phone-login__submit-btn:hover {
  background-color: var(--hover);
  color: var(--jasmine);
  cursor: pointer;
}

.main-login__submit-btn:focus,
.phone-login__otp-btn:focus,
.phone-login__submit-btn:focus {
  background-color: var(--jasmine);
  color: var(--information);
  border: 2px solid #20c5ff;
  transition: box-shadow 0.3s ease;
  outline: none;
}

.main-login__submit-btn:disabled,
.phone-login__otp-btn:disabled,
.phone-login__submit-btn:disabled {
  background-color: var(--ghost);
  color: var(--ash);
}

/* phone Input */
.phone-input__msg--error {
  text-align: center;
  color: var(--error);
  padding-bottom: 10px;
}

/* recaptcha */
.main-login__recaptcha {
  display: flex;
  justify-content: center;
}

/* login ms */
.main-login-ms__btn {
  transition: all 0.5s ease;
  display: block;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 4px;
  width: 90%;
  height: 2.4rem;
  padding: 0.4rem;
  margin: auto;
  color: #757575;
  font: 500 16px var(--sisa-font-family);
  background-image: url("https://learn.microsoft.com/en-us/azure/active-directory/develop/media/howto-add-branding-in-azure-ad-apps/ms-symbollockup_mssymbol_19.svg");
  background-color: var(--white);
  background-repeat: no-repeat;
  background-position: 12px 8px;
}

.ms-login {
  margin-top: 10px;
  margin-bottom: 1rem;
}

/* check */
.main-login__nav {
  width: 100%;
  height: 75px;
  padding-top: 20px;
  opacity: 1;
  transition: all 0.5s ease;
  text-transform: uppercase;
}

.main-login__ul {
  width: 100vw;
}

.main-login__li {
  padding-left: 10px;
  font-size: 1rem;
  display: inline;
  text-align: left;
  padding-right: 10px;
}

.main-login__li {
  display: inline;
  cursor: pointer;
  width: 50%;
  color: var(--turquoise);
  text-decoration: none;
  transition: all 0.25s ease;
}

.main-login__li:hover {
  color: #dadada;
  padding-bottom: 10px;
  border-bottom: solid 2px #4a69b1;
}

.main-login__li.active {
  padding-bottom: 10px;
  color: var(--white);
  text-decoration: none;
  border-bottom: solid 2px var(--turquoise);
  transition: all, transform 2s, ease-in;
  cursor: pointer;
}

/* google */
.google-login__btn {
  transition: all 0.5s ease;
  display: block;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 4px;
  width: 90%;
  height: 2.4rem;
  padding: 0.4rem;
  margin: auto;
  color: #757575;
  font: 500 16px var(--sisa-font-family);
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
  background-color: var(--white);
  background-repeat: no-repeat;
  background-position: 12px 8px;
}

.google-login__btn:active {
  background-color: #eeeeee;
}

/* phone-login */
.phone-login__btn {
  transition: all 0.5s ease;
  display: block;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  width: 90%;
  height: 2.4rem;
  padding: 0.4rem;
  margin: 10px auto;
  color: #757575;
  font: 500 16px var(--sisa-font-family);
  background-color: var(--white);
  background-position: 12px 8px;
}

.phone-login__btn:active {
  background-color: #eeeeee;
}

.phone-login__text-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.phone-login__text-box-icon {
  width: 10%;
}

.phone-login__text-box-p {
  width: 80%;
}

@media only screen and (max-width: 500px) {
  .phone-login__frame {
    width: 100%;
  }
}

@keyframes slide {
  0% {
    transform: translateX(-25%);
  }

  100% {
    transform: translateX(25%);
  }
}

/* *:focus {
  outline: 0;
} */

.simple_modal-container {
  padding: 1rem 2rem;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 5px hsl(0, 0%, 35%);
  min-width: 30em;
  max-width: 80%;
}

.simple_modal-container::backdrop {
  background: rgba(0, 0, 0, 0.5);
}

.simple_modal-content {
  padding: 0.5rem;
}

.simple_modal-title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  font: 600 1.5rem var(--sisa-font-family);
}

.simple_modal-title {
  color: var(--indigo);
  font-size: 20px;
}

.simple_modal-divider {
  background-color: gray;
  height: 2px;
  margin: 1rem 0;
}

.simple_modal-content-body {
  font: 1rem var(--sisa-font-family);
  color: var(--indigo);
  margin: 1rem 0;
  text-align: initial;
}

.dialog-building-name {
  font-weight: 600;
}

.simple_modal-progress {
  margin: 0.5rem 0;
}

.simple_modal-error-message {
  font-weight: 600;
  text-align: center;
  color: var(--error);
  margin: 5px;
}

.simple_modal-success-message {
  font-weight: 600;
  text-align: center;
  color: green;
  margin: 5px;
}

.simple_modal-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 1rem 0;
  gap: 0.5rem;
}

.inner-components {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 1rem 0;
  gap: 1rem;
}

.inner-components hr,
.inner-components .checkbox,
.inner-components .full-width {
  width: 100%;
}

.inner-components .form__buttons__right {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.btn-close {
  background-color: var(--jasmine);
  color: var(--information);
  border-radius: 4px;
  height: 28px;
  width: 30px;
  justify-content: center;
  border: 1px solid var(--information);
  display: flex;
  flex-direction: row;
  align-items: center;
}

.btn-close:active,
.btn-close:hover {
  background-color: var(--information);
  color: var(--jasmine);
}

.btn-close:focus {
  background-color: var(--jasmine);
  color: var(--information);
  border: 2px solid #20c5ff;
  transition: box-shadow 0.3s ease;
  outline: none;
}

.btn-cancel {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  min-width: 8.7rem;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  background-color: var(--jasmine);
  color: var(--information);
  inline-size: auto;
  font: 600 1rem var(--sisa-font-family);
  border: 1px solid var(--information);
}

.btn-cancel:active,
.btn-cancel:hover {
  background-color: var(--information);
  color: var(--jasmine);
}

.btn-cancel:focus {
  background-color: var(--jasmine);
  color: var(--information);
  border: 2px solid #20c5ff;
  transition: box-shadow 0.3s ease;
  outline: none;
}

.btn-confirm {
  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  min-width: 8.7rem;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  background-color: var(--information);
  color: var(--jasmine);
  inline-size: auto;
  font: 500 16px var(--sisa-font-family);
}

.btn-confirm:active,
.btn-confirm:hover {
  background-color: var(--hover);
  color: var(--jasmine);
}

.btn-confirm:focus {
  background-color: var(--information);
  color: var(--jasmine);
  border: 2px solid #20c5ff;
  transition: box-shadow 0.3s ease;
  outline: none;
}

.btn-cancel:disabled,
.btn-confirm:disabled {
  background-color: var(--ghost);
  color: var(--ash);
  font-weight: bold;
  border: none;
}

.file-selector-container,
.file-selector-filename {
  margin: 0.5rem 0;
}

.remove-property-dialog,
.update-property-dialog {
  width: 100%;
  display: flex;
  font: 500 16px/24px var(--sisa-font-family);
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.remove-property-dialog {
  color: var(--charcoal);
  font-weight: 500;
}

.remove-property-dialog:hover {
  cursor: pointer;
  color: var(--error);
}

.remove-property-dialog:hover svg {
  fill: var(--error);
}

.update-property-dialog:hover {
  opacity: 0.5;
  cursor: pointer;
  transition: all 0.5s;
}

.delete-font-awesome {
  width: 13px;
  height: 25px;
  margin-left: 9px;
}

#dialog:first-child {
  outline: none;
}

.empty {
  background-color: var(--white);
}

.empty:focus {
  background-color: var(--white);
  outline: none;
}
.checkbox__label {
  display: flex;
  margin-top: 8px;
  align-items: center;
  position: relative;
  user-select: none;
  font: 400 1rem/1.5rem Work Sans;
  color: #141414;
  cursor: pointer;
}
.checkbox__label .checkbox__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 20px;
  height: 20px;
  margin-right: 8px;
  border-radius: 0.125rem;
  border: 1px solid #b3b3b3;
  background-color: #f9f9f9;
}
input:checked + .checkbox__label .checkbox__icon {
  background: #224bff;
  border-color: #224bff;
}
input:indeterminate + .checkbox__label .checkbox__icon {
  background: #224bff;
  border-color: #224bff;
}
input:disabled + .checkbox__label .checkbox__icon {
  background: #737373;
  border-color: #737373;
  cursor: not-allowed;
}
.checkbox__label--disabled {
  display: flex;
  margin-top: 8px;
  align-items: center;
  position: relative;
  user-select: none;
  font: 400 1rem/1.5rem Work Sans;
  color: #737373;
  cursor: not-allowed;
}
.checkbox__label--disabled .checkbox__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 20px;
  height: 20px;
  margin-right: 8px;
  border-radius: 0.125rem;
  border: 1px solid #b3b3b3;
  background-color: #f9f9f9;
}
input:checked + .checkbox__label--disabled .checkbox__icon {
  background: #224bff;
  border-color: #224bff;
}
input:indeterminate + .checkbox__label--disabled .checkbox__icon {
  background: #224bff;
  border-color: #224bff;
}
input:disabled + .checkbox__label--disabled .checkbox__icon {
  background: #737373;
  border-color: #737373;
  cursor: not-allowed;
}
/* Invalid unchecked state */
.invalid .checkbox__icon {
  border-color: #cf0a2e;
  color: #f9f9f9;
}
/* Invalid checked state */
.invalid input:checked + .checkbox__label .checkbox__icon {
  background: #cf0a2e;
  border-color: #cf0a2e;
}
/* Invalid indeterminate state */
.invalid input:indeterminate + .checkbox__label .checkbox__icon {
  background: #cf0a2e;
  border-color: #cf0a2e;
}

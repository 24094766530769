.component-container {
  display: flex;
  height: fit-content;
  align-items: center;
  width: 100%;
  background-color: lightblue;
  border-radius: 10px;
  border: solid 2px lightblue;
  padding: 0.5rem 1rem;
  cursor: pointer;
}

.icon-container {
  display: flex;
  margin: 5px 10px;
  height: fit-content;
}

.message-container {
  width: 100%;
  text-align: center;
}

.status-message {
  font-size: 1rem;
}

body{
  font-family: var(--sisa-font-family);
}

.Collapsible__trigger {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.Collapsible__trigger.is-open svg {
  transform: rotate(180deg);
}

.container {
  justify-content: space-between;
  flex-direction: column;
  height: auto;
  display: flex;
}

.container .btn-holder {
  justify-content: flex-end;
  display: flex;
}

/* Go top button */
.go_top {
  width: 40px;
  height: 40px;
  position: fixed;
  background: #333;
  color: var(--white);
  right: 50px;
  bottom: 50px;
  text-align: center;
  line-height: 40px;
  display: none;
  transition: opacity 0.5s;
}

.fade-out {
  opacity: 0;
}

/* new style for the budget reset filter btn */
#budget-reset-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  width: 100%;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  background-color: var(--jasmine);
  color: var(--information);
  border: none;
  font: 600 16px var(--sisa-font-family);
  border: 1px solid var(--information);
  box-shadow: none;
  font-family: var(--sisa-font-family);
}

#budget-reset-btn:active,
#budget-reset-btn:hover {
  background-color: var(--information);
  cursor: pointer;
  color: var(--jasmine);
}

#budget-reset-btn:focus {
  background-color: var(--jasmine);
  color: var(--information);
  border: 2px solid #20c5ff;
  transition: box-shadow 0.3s ease;
  outline: none;
}

/* new style for the budget apply change btn */
#budget-apply-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 2.5rem;
  width: 100%;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  background-color: var(--information);
  color: var(--jasmine);
  border: none;
  box-shadow: none;
}

#budget-apply-btn:active,
#budget-apply-btn:hover {
  background-color: var(--hover);
  cursor: pointer;
}

#budget-apply-btn:focus {
  background-color: var(--information);
  border: 2px solid #20c5ff;
  transition: box-shadow 0.3s ease;
  outline: none;
  /* Remove default focus outline */
}

#budget-apply-btn:disabled,
#budget-reset-btn:disabled {
  background-color: var(--ghost);
  color: var(--ash);
  font-weight: bold;
  border: none;
}

#portfolio-filter-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
}

/*
#filters-button {
  background-color: #03045e;
  width: 48%;
  height: 40px;
  margin-top: 56px;
} */

.slider-container {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  border: 1px solid var(--mist);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--ghost);
  margin-top: 8px;
  margin-bottom: 8px;
  padding-left: 16px;
  padding-right: 16px;
}

/* For the control */
.select__control {
  height: 40px;
  border-radius: 4px;
  width: 100%;
  border-color: var(--mist) !important;
}

/* For the menu portal */
.select__menu-portal {
  z-index: 9999;
}

@media (min-width: 768px) {
  #filter-buttons-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-self: flex-end;
    height: fit-content;
    gap: 10px;
    margin-top: 3.4rem;
  }
}

@media (max-width: 768px) {
  #portfolio-filter-container {
    grid-template-columns: none;
    gap: 0;
  }

  #buget-input-lable {
    margin-top: 2px;
  }

  #available-upgrade-section {
    overflow-y: scroll;
  }

  #available-upgrade-section-point {
    scroll-margin-top: 140px;
  }

  #portfolio-filter-section,
  #available-upgrade-section,
  #portfolio-upgrade-section {
    scroll-margin-top: 60px;
  }

  .go_top {
    display: block;
  }

  #analysis-container {
    scroll-margin-top: 60px;
  }

  /* #filters-button {
    background-color: #03045e;
    width: 100%;
    height: 40px;
    margin-top: 16px;
  } */

  #filter-buttons-container {
    display: grid;
    margin-top: 1rem;
    gap: 1rem;
  }
}

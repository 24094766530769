.dropdown__tab {
  display: flex;
  height: 2.5rem;
  padding: 0.75rem;
  align-items: center;
  background: rgba(249, 249, 249, 0.25);
  color: #224bff;
  font-family: Work Sans;
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  width: 100%;
  min-width: 8.875rem;
  cursor: pointer;
  text-wrap: nowrap;
}
.dropdown__tab.selected {
  background: #f9f9f9;
  color: #011148;
}
.dropdown__tab:hover {
  color: #2e45be;
  background: #e6e6e6;
}
.dropdown__tab:focus {
  color: #224bff;
  transition: box-shadow 0.3s ease;
  outline: none;
}
.dropdown__tab.disabled {
  color: #737373;
  background: #f9f9f9;
  cursor: not-allowed;
}

.progress-bar-container {
    position: relative;
}

.progress-value-text {
    position: absolute;
    left: 0;
    top: 0;
    transform: translateX(50%);
}

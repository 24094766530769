/* @tailwind base; */
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Mukta&display=swap");

body {
    background-color: #f3f6fd;
}

#dashboard {
    display: flex;
    align-content: center;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
}

/* .main-cont{
    height: 92vh;
    width: 83vw;
    margin-left: 15vw;
    margin-top: 4vh;
} */

.dashButton {
    width: 33%;
    height: 13vh;
    margin-right: 1vw;
    display: flex;
    align-items: center;
    border-radius: 2vw;
    color: var(--white);
    background-color: #03045e;
    /*border-color: #ad4501;
    border-style: solid;
    border-bottom: 5px;*/
}

#port-btn {
    background-color: #279be4;
}

#solution-btn {
    background-color: #88d4ab;
}

#news-btn {
    background-color: #ed254e;
}

svg.bi.bi-clipboard-data,
svg.bi.bi-lightbulb,
svg.bi.bi-newspaper {
    opacity: 0.8;
}

.btn-title {
    display: block;
    margin-left: 2vw;
}

#grid-1 {
    grid-area: elecPie;
}

#grid-2 {
    grid-area: gasPie;
}

#grid-5 {
    grid-area: table;
}

#breakdowns {
    display: grid;
    grid-template-areas: "elecPie gasPie";
    gap: 10px;
    /* padding: 1vh; */
}


h1.margin {
    color: black;
}

.widget {
    background: var(--white);
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    /* float: left; */
    /* width: 40vw; */
    /* margin: auto; */
}

.widget-header {
    /* background: #112b4e;
    border-left: 0.25px solid #ddd;
    border-right: 0.25px solid #ddd;
    border-bottom: 0.25px solid #ddd; */
    padding: 1rem;
}

/* .widget-header h2 {
    font-size: 15px;
    font-weight: lighter;
    margin: 0;
    padding: 11px 15px;
    color: var(--jasmine);
    display: inline-block;
    /* text-transform: capitalize;
} */

.widget-header-expanded {
    background: #6d6d6d;
    border-left: 0.25px solid #ddd;
    border-right: 0.25px solid #ddd;
    border-bottom: 0.25px solid #ddd;
    padding: 0.5vh 1vw 1vh 1vh;
}

.widget-header-expanded h2 {
    font-size: 15px;
    font-weight: lighter;
    margin: 0;
    padding: 11px 15px;
    color: var(--jasmine);
    display: inline-block;
    text-transform: capitalize;
}

.empty {
    height: 5vh;
}

.widget-container {
    width: fit-content;
    margin-bottom: 3vh;
}

.tab-icon {
    background-color: #bbb;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 1vw;
}

.float-container {
    width: 81.5vw;
}

.float-child {
    width: 50%;
    float: left;
}

#bar-chart-container {
    margin: auto;
}


@media (max-width: 1024px) {
    .title {
        /* background-color: #03045E; */
        width: 100% !important;
        height: auto;
    }

    .title>h1 {
        color: var(--indigo);
        margin-left: 0;
        margin-right: auto;

    }

    .widget-header {
        border-left: 0px;
        border-right: 0px;
    }
}

@media (max-width: 768px) {

    #barchar-in-portfolio,
    #piechart-in-portfolio {
        /* overflow-y: scroll; */
        display: block;
    }

    #building-table-section,
    #consumption-table-section {
        overflow-y: scroll;
    }

    .chart-container {
        overflow-y: scroll;
    }

    #piechar-container,
    #barchar-container {
        height: auto;
    }

    #toggle-container {
        flex-direction: column;

    }

    #toggle-container>div:first-child {
        display: block;
        height: 48px;
    }

    #toggle-container>div:hover {
        background-color: darkgray;

    }

    .portfolio-component {
        width: 100%;
    }

    .custom-zoom {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }

    #graph-input-field {
        display: block;
    }

    .preset-zoom {
        display: flex;
        justify-content: center;
    }

    .preset-zoom>button {
        width: auto;
    }

    .active_menu {
        display: none;
    }

    #building-table-section,
    #consumption-table-section {
        scroll-margin-top: 50px;
    }

    #utility-ghg-section,
    #historial-data-section,
    #piechart-first-section,
    #piechart-second-section,
    #barchart-first-section,
    #barchart-second-section {
        scroll-margin-top: 60px;
    }

    .sections {
        font-family: var(--sisa-font-family);
        @apply text-center text-lg grow;
        @apply border-2 border-transparent border-b-[#dee2e6];
        @apply hover:border-x-2 hover:border-t-2 hover:border-x-[#dee2e6] hover:border-t-[#dee2e6];
        @apply px-2 py-2;
        @apply cursor-pointer;
    }

    .active-section {
        display: none;
    }

    #portfolio-title {
        scroll-margin-top: 60px;
    }

    #bar-chart-container {
        margin: 0px;
        padding: 0px;
    }

}

.import_container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  gap: 24px;
}
.instruction_container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  gap: 12px;
}
.icon_box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  margin: 0 6px;
}
.text_with_icon {
  display: flex;
  align-items: center;
  gap: 6px;
}
.status_text--complete,
.status_text--warning,
.status_text--error {
  font-size: 18px;
  font-weight: 600;
}
.status_text--complete {
  color: #008611;
}
.status_text--warning {
  color: #eb6b14;
}
.status_text--error {
  color: #cf0a2e;
}
.inline_text--bold {
  font-weight: bold;
}
.buttons_container {
  display: flex;
  flex-direction: row;
  gap: 12px;
}
.processed_buildings_table,
.error_buildings_table {
  width: 100%;
  max-height: 500px;
  overflow: auto;
  border: solid 1px #b3b3b3;
}
.processed_buildings_table--title,
.error_buildings_table--title {
  font-size: 18px;
  font-weight: 600;
}

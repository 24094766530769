.reviewContainer {
  margin: 10px 10px;
  position: relative;
}

.reviewDivRow {
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-gap: 20px;
  margin: 12px 0px;
  width: 85%;
}

.reviewDivLabel {
  font-weight: 600;
  color: rgb(60, 62, 65);
}

.reviewDivValue {
  overflow-wrap: break-word;
  overflow: auto;
}

.editBtn {
  background-color: var(--information);
  width: 8.7rem;
  height: 2.5rem;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  color: var(--jasmine);
  font: 600 1rem var(--sisa-font-family);
  text-align: center;
  text-transform: capitalize;
  margin: 10px;
  cursor: pointer;
  position: absolute;
  right: 0px;
  top: 10px;
  line-height: 24px;
}

.editBtn:active,
.editBtn:hover {
  background-color: var(--hover);
  cursor: pointer;
  color: var(--jasmine);
}

.editBtn:focus {
  background-color: var(--information);
  border: 2px solid #20c5ff;
}

.editBtn:disabled {
  background-color: var(--ghost);
  color: var(--ash);
}

.review_submition {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 10px 0px;
  gap: 10px;
  margin: 20px;
  height: 50vh;
}

.redenderBtn {
  display: flex;
  text-decoration: none;
  justify-content: center;
  text-align: center;
  align-items: center;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 5vh;
  width: 30vw;
  height: 10vh;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 10px;
  gap: 10px;
}

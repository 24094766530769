.radio-button__label {
  display: flex;
  align-items: center;
  position: relative;
  user-select: none;
  font: 400 1rem/1.5rem Work Sans;
  color: #141414;
  padding: 4px;
  cursor: pointer;
}
.radio-button__label .radio-button__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 20px;
  height: 20px;
  margin-right: 8px;
  border-radius: 50%;
  border: 2px solid #737373;
  background-color: #f9f9f9;
}
input:checked + .radio-button__label .radio-button__icon {
  border: 6px solid #224bff;
}
.invalid input:checked + .radio-button__label .radio-button__icon {
  border: 6px solid #cf0a2e;
}
input:disabled + .radio-button__label .radio-button__icon {
  border-color: #737373;
  background-color: #f9f9f9;
}
input:disabled + .radio-button__label {
  cursor: not-allowed;
  color: #737373;
}

.user-storage-container {
  display: grid;
  gap: 0.25rem;
}

.user-storage-progress-text {
  padding-block: 0.25rem;
  display: flex;
  justify-content: space-between;
}

.alert-banner--default {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 1rem 1.5rem;
  align-items: center;
  gap: 1rem;
  border-radius: 0.1875rem;
  color: #141414;
  font: 600 0.875rem/1.25rem Work Sans;
  border-left: 8px solid #e6e6e6;
  background: rgba(230, 230, 230, 0.5);
  z-index: 1;
  position: absolute;
  bottom: 3rem;
  max-width: 56.25rem;
}
.alert-banner--default .alert-banner__icon {
  width: 1.5rem;
  height: 1.5rem;
}
.alert-banner--information {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 1rem 1.5rem;
  align-items: center;
  gap: 1rem;
  border-radius: 0.1875rem;
  color: #141414;
  font: 600 0.875rem/1.25rem Work Sans;
  border-left: 8px solid #e6e6e6;
  background: rgba(230, 230, 230, 0.5);
  z-index: 1;
  position: absolute;
  bottom: 3rem;
  max-width: 56.25rem;
  background: rgba(34, 75, 255, 0.5);
  border-left: 8px solid #224bff;
}
.alert-banner--information .alert-banner__icon {
  width: 1.5rem;
  height: 1.5rem;
}
.alert-banner--success {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 1rem 1.5rem;
  align-items: center;
  gap: 1rem;
  border-radius: 0.1875rem;
  color: #141414;
  font: 600 0.875rem/1.25rem Work Sans;
  border-left: 8px solid #e6e6e6;
  background: rgba(230, 230, 230, 0.5);
  z-index: 1;
  position: absolute;
  bottom: 3rem;
  max-width: 56.25rem;
  background: rgba(0, 134, 17, 0.5);
  border-left: 8px solid #008611;
}
.alert-banner--success .alert-banner__icon {
  width: 1.5rem;
  height: 1.5rem;
}
.alert-banner--warning {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 1rem 1.5rem;
  align-items: center;
  gap: 1rem;
  border-radius: 0.1875rem;
  color: #141414;
  font: 600 0.875rem/1.25rem Work Sans;
  border-left: 8px solid #e6e6e6;
  background: rgba(230, 230, 230, 0.5);
  z-index: 1;
  position: absolute;
  bottom: 3rem;
  max-width: 56.25rem;
  background: rgba(235, 107, 20, 0.5);
  border-left: 8px solid #eb6b14;
}
.alert-banner--warning .alert-banner__icon {
  width: 1.5rem;
  height: 1.5rem;
}
.alert-banner--error {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  padding: 1rem 1.5rem;
  align-items: center;
  gap: 1rem;
  border-radius: 0.1875rem;
  color: #141414;
  font: 600 0.875rem/1.25rem Work Sans;
  border-left: 8px solid #e6e6e6;
  background: rgba(230, 230, 230, 0.5);
  z-index: 1;
  position: absolute;
  bottom: 3rem;
  max-width: 56.25rem;
  background: rgba(207, 10, 46, 0.5);
  border-left: 8px solid #cf0a2e;
}
.alert-banner--error .alert-banner__icon {
  width: 1.5rem;
  height: 1.5rem;
}
.alert-banner__summary {
  gap: 1rem;
  display: flex;
  align-items: center;
  color: #141414;
  font: 600 0.875rem/1.25rem Work Sans;
  white-space: nowrap;
  width: 100%;
}
.alert-banner__summary-icon {
  display: flex;
  align-items: center;
}
.alert-banner__summary-text {
  flex: 1;
}
.alert-banner__button-close,
.alert-banner__details-button {
  color: #141414;
  display: flex;
  justify-content: flex-end;
}
.alert-banner__button-close .alert-banner__button-close-icon,
.alert-banner__details-button .alert-banner__button-close-icon,
.alert-banner__button-close .alert-banner__button-detail-icon,
.alert-banner__details-button .alert-banner__button-detail-icon {
  width: 1.5rem;
  height: 1.5rem;
}
.alert-banner--expandable {
  flex-direction: row;
  align-items: flex-start;
  gap: 0.5rem;
}
.alert-banner--expandable .alert-banner__details-p {
  display: flex;
  width: 100%;
  padding: 1rem 1rem 0.5rem 2.5rem;
  flex-direction: column;
  align-items: flex-start;
  font: 400 0.875rem/1.25rem Work Sans;
}
.alert-banner--expandable .alert-banner__button-group {
  display: flex;
  padding: 0rem 2.5rem;
  align-items: flex-start;
  gap: 1rem;
  align-self: stretch;
}
.alert-banner--animation {
  animation-timing-function: ease-out;
  animation-duration: 300ms;
  animation-name: bannerDisappear;
  animation-fill-mode: forwards;
}
@keyframes bannerDisappear {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(100%);
  }
}

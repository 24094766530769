body {
    font-family: var(--sisa-font-family);
}

.navbar-toogleBtn,
.navbar-toogleBtn2 {
    display: none;
}

.ecm-header {
    background: var(--white);
    color: var(--indigo);
    font-family: var(--sisa-font-family);
}

.title-btn-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.report-title-text {
    padding: 1rem;
    color: var(--indigo);
    font: 600 20px/28px var(--sisa-font-family);
}

.report-title-text-mobile {
    padding: .5rem;
    color: var(--indigo);
    font: 600 16px/24px var(--sisa-font-family);
}

.title-link {
    text-decoration: underline;
    color: var(--information);
    margin-bottom: 0.5rem;
}

@media (max-width:1024px) {
    .ecm-header {
        border-left: none;
        border-right: none;
    }
}


@media (max-width: 768px) {
    #report-container {
        width: 100%;
    }

    #gas-piechart-container,
    #electric-piechart-container {
        display: block;
    }

    #tab-container>div:first-child {
        display: block;
        height: 45px;
        color: var(--indigo);
        font: 600 16px/24px var(--sisa-font-family);
    }

    .navbar-toogleBtn {
        display: block;
        position: absolute;
        right: 32px;
        font-size: 30px;
    }

    .nav_menu,
    .sub_nav_menu {
        display: none;
    }

    .navbar-toogleBtn2 {
        display: block;
        position: absolute;
        right: 32px;
        font-size: 30px;
    }

    .report-header {
        display: none;
    }
}

#table_description {
    font-family: var(--sisa-font-family);
    border-collapse: collapse;
    border: 1px solid var(--ghost);
    width: 100%;
    text-align: left;
}

#table_description th {
    font-size: 16px;
    font-weight: 500;
    padding: 1rem;
    background-color: var(--ghost);
    border: 1px solid var(--mist);
}

#table_description td {
    border: 1px solid var(--ghost);
    padding: 1rem;
    font-weight: 400;
    font-size: 16px;
}

#table_description tr {
    background-color: var(--white);
}

#table_description tr:hover {
    background-color: var(--ghost);
}

@media (max-width: 768px) {
    #expanded-tablepanel-container {
        display: block;
    }

    #table_description>thead {
        display: none;
    }

    #panel-container {
        margin-bottom: 10px;
    }

}

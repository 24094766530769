.import-espm-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (min-width: 45em) {
  .import-espm-form {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
}

.import-espm-form .input-label-espm {
  color: #112e4b;
  font-weight: 600;
}

.input-text-espm {
  font-size: 12pt;
  font-weight: 600;
  color: #112e4b;
  padding: 5px;
  margin: 1em;
  border-radius: 5px;
  border: solid 1px lightgray;
}
